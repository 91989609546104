import React from 'react'
import { Element } from 'react-bulma-components'

interface JobTitleExplanationProps {
  textColor?: string
}

const JobTitleExplanation: React.FC<JobTitleExplanationProps> = ({ textColor }) => {
  return (
    <>
      <Element mb={2}>
        L'intitulé de poste est un élément central qui définit les caractéristiques d'un poste de
        travail. Chaque mission doit obligatoirement être associée à un intitulé de poste qui
        détermine :
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Règles de Pointage
        </Element>
        <Element>
          Chaque intitulé de poste est associé à une règle de pointage qui définit comment sont
          traités les pointages (retards, avances, pauses) pour toutes les journées de travail sur
          ce poste.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Informations Complémentaires
        </Element>
        <Element>
          Description du poste, compétences souhaitées, certifications nécessaires... Ces
          informations facilitent la recherche et l'attribution des missions.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Le Coefficient de Main d'Œuvre
        </Element>
        <Element>
          Pour le travail temporaire, le coefficient de facturation est défini par agence d'intérim
          sur chaque intitulé de poste. Ce coefficient est utilisé pour le calcul des coûts de
          mission.
        </Element>
      </Element>

      <Element>
        <Element renderAs="i" textSize={7}>
          Ces paramètres sont automatiquement appliqués à toutes les missions créées avec cet
          intitulé de poste.
        </Element>
      </Element>
    </>
  )
}

export default JobTitleExplanation
