import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './employer-settings-wrapper'
import { useWorkPeriodEventsQuery } from '../../queries/work-period-events'
import WorkPeriodEventsList from '../../components/work-period-events/list'
import WorkPeriodEventModalForm from '../../components/work-period-events/work-period-event-modal-form'
import { WorkPeriodEvent } from '../../api/work-period-events'
import { Box } from 'react-bulma-components'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import WorkPeriodEventExplanation from '../../components/work-period-events/work-period-event-explanation'
import { AddResourceIcon } from '../../components/icons'

/**
 * Component for managing employer work period events
 * Receives employer from outlet context and passes it to the list
 */
const WorkPeriodEventsSettings: React.FC = () => {
  const { employer } = useOutletContext<OutletContext>()
  const { data: events } = useWorkPeriodEventsQuery({
    enabled: Boolean(employer),
  })
  const [currentWorkPeriodEvent, setCurrentWorkPeriodEvent] = React.useState<
    WorkPeriodEvent | 'new' | undefined
  >()

  return (
    <>
      <SidebarContentTitle
        title="Evènements"
        onButtonClick={() => setCurrentWorkPeriodEvent('new')}
        addButtonLabel="Nouvel évènement"
        buttonDataTestId="new-work-period-event"
        ButtonIcon={AddResourceIcon}
        help={<WorkPeriodEventExplanation textColor={'white'} />}
      />

      <Box>
        <WorkPeriodEventsList
          workPeriodEvents={events || []}
          onEventClick={workPeriodEvent => setCurrentWorkPeriodEvent(workPeriodEvent)}
        />
      </Box>
      <WorkPeriodEventModalForm
        isDisplayed={Boolean(currentWorkPeriodEvent)}
        setIsDisplayed={() => setCurrentWorkPeriodEvent(undefined)}
        workPeriodEvent={currentWorkPeriodEvent === 'new' ? undefined : currentWorkPeriodEvent}
      />
    </>
  )
}

export default WorkPeriodEventsSettings
