import React from 'react'
import { Tag } from 'react-bulma-components'

interface NotificationBadgeProps {
  count?: number
  // Allows customization of the title text
  getTitle?: (count: number) => string
}

/**
 * Reusable component to display a notification badge
 * Used in header and sidebar for pending items
 */
const NotificationBadge: React.FC<NotificationBadgeProps> = ({
  count,
  getTitle = count => `${count} item${count > 1 ? 's' : ''}`,
}) => {
  if (!count) return null

  return (
    <Tag color="warning" size="small" data-tooltip={getTitle(count)}>
      {count}
    </Tag>
  )
}

export default NotificationBadge
