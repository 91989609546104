import React from 'react'
import { Element } from 'react-bulma-components'

/**
 * Component that explains the concept of interim agency members
 * @param {Object} props - Component props
 * @param {string} props.textColor - Color of the text (optional)
 */
interface InterimAgencyMemberExplanationProps {
  textColor?: string
}

const InterimAgencyMemberExplanation: React.FC<InterimAgencyMemberExplanationProps> = ({
  textColor,
}) => {
  return (
    <>
      <Element mb={2}>
        Les gestionnaires de votre agence sont les utilisateurs qui ont accès à votre espace agence
        sur la plateforme. Ils peuvent :
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Gérer les Intérimaires
        </Element>
        <Element>
          Inviter et gérer les intérimaires de l'agence, suivre leurs missions et leurs pointages,
          et accéder à leurs relevés d'heures détaillés.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Gérer les Missions
        </Element>
        <Element>
          Créer et suivre les missions d'intérim, affecter les intérimaires aux missions, et gérer
          les demandes des entreprises partenaires.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Gérer les Partenariats
        </Element>
        <Element>
          Établir et gérer les relations avec les entreprises clientes, définir les coefficients de
          facturation par poste, et suivre les conditions de collaboration.
        </Element>
      </Element>

      <Element>
        <Element renderAs="i" textSize={7}>
          Tous les gestionnaires de l'agence ont les mêmes droits d'accès aux fonctionnalités de la
          plateforme.
        </Element>
      </Element>
    </>
  )
}

export default InterimAgencyMemberExplanation
