import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { InterimAgency } from '../../api/organizations'
import AgencyInfosForm from '../../components/organizations/agency-infos-form'
import { Box } from 'react-bulma-components'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'

interface AgencyInfoProps {
  interimAgency?: InterimAgency
}

const AgencyInfo: React.FC = () => {
  const { interimAgency } = useOutletContext<AgencyInfoProps>()

  return (
    <>
      <SidebarContentTitle title="Informations de l'agence" />
      <Box>
        <AgencyInfosForm interimAgency={interimAgency} />
      </Box>
    </>
  )
}

export default AgencyInfo
