import React from 'react'
import { Element } from 'react-bulma-components'
import WorkPeriodEventBenefitDescription from './work-period-event-benefit-description'
import WorkPeriodEventInformativeDescription from './work-period-event-informative-description'

interface WorkPeriodEventExplanationProps {
  textColor?: string
}

const WorkPeriodEventExplanation: React.FC<WorkPeriodEventExplanationProps> = ({ textColor }) => {
  return (
    <>
      <Element mb={2}>
        Les événements peuvent être attachés aux journées de travail pour indiquer des situations
        particulières (absences, congés, déplacements...). <br />
        Il en existe deux types :
      </Element>

      <Element mb={3}>
        <WorkPeriodEventBenefitDescription textColor={textColor} />
      </Element>

      <Element>
        <WorkPeriodEventInformativeDescription textColor={textColor} />
      </Element>
    </>
  )
}

export default WorkPeriodEventExplanation
