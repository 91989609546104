import React from 'react'
import { Element } from 'react-bulma-components'

interface WorkerExplanationProps {
  textColor?: string
}

const WorkerExplanation: React.FC<WorkerExplanationProps> = ({ textColor }) => {
  return (
    <>
      <Element mb={2}>
        Les intérimaires sont inscrits par leur agence d'intérim et accèdent à leurs données via
        l'application mobile TeamTim pour :
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Le Pointage
        </Element>
        <Element>
          Chaque intérimaire dispose d'un QR code unique dans son profil mobile qui lui permet de
          pointer sur les bornes de l'entreprise au début et à la fin de sa journée de travail.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Missions
        </Element>
        <Element>
          L'intérimaire peut consulter sur son mobile ses missions en cours et à venir, avec les
          informations essentielles comme :
          <ul>
            <li>Les horaires de travail</li>
            <li>Le lieu de la mission</li>
            <li>L'intitulé du poste</li>
          </ul>
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Relevés d'Heures
        </Element>
        <Element>
          Accès via l'application à l'historique des pointages et aux relevés d'heures détaillés,
          incluant les heures travaillées et les éventuelles majorations.
        </Element>
      </Element>

      <Element>
        <Element renderAs="i" textSize={7}>
          La communication avec l'agence d'intérim se fait en dehors de l'application. Pour toute
          question sur vos missions, contactez directement votre agence.
        </Element>
      </Element>
    </>
  )
}

export default WorkerExplanation
