import React from 'react'
import { Formik } from 'formik'
import { Columns } from 'react-bulma-components'
import AutoSubmit from '../form/utils/auto-submit'
import OrganizationFilterField from '../filters/fields/organization'
import { OrganizationTypeEnum } from '../../api/organizations'
import useFilters from '../../hooks/filters'
import { FiltersResourcesEnum, LaborCostsCoefficientsFilters } from '../../store/filters'
import JobTitleFilterField from '../filters/fields/job-title'

const LaborCostsCoefficientsFilters: React.FC = () => {
  const { setFilters, filters } = useFilters(FiltersResourcesEnum.laborCostsCoefficients)

  return (
    <Formik<LaborCostsCoefficientsFilters>
      initialValues={{
        interimAgency: filters.interimAgency || undefined,
        jobTitle: filters.jobTitle || undefined,
      }}
      onSubmit={values => {
        setFilters(FiltersResourcesEnum.laborCostsCoefficients, values)
      }}
    >
      {props => (
        <>
          <Columns mb="0">
            <OrganizationFilterField type={OrganizationTypeEnum.interimAgency} singleSelect />
            <JobTitleFilterField />
          </Columns>
          <AutoSubmit values={props.values} submitForm={props.submitForm} />
        </>
      )}
    </Formik>
  )
}

export default LaborCostsCoefficientsFilters
