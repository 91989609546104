import React, { ReactNode } from 'react'
import { Element, Heading, Icon, Tag } from 'react-bulma-components'
import { ResponsivePie } from '@nivo/pie'

type KPIItemProps = {
  title?: string
  data: [{ id: string; value: number; color: string }, { id: string; value: number; color: string }]
  detailIndex: 0 | 1
}

export const KPIItem = ({ title, data, detailIndex }: KPIItemProps): ReactNode => {
  const noData = data[0].value === 0 && data[1].value === 0
  const theOtherIndex = detailIndex === 0 ? 1 : 0
  const success = data[1].value === 0
  const fail = data[0].value === 0
  const percentage = Math.floor(
    (data[detailIndex].value / (data[detailIndex].value + data[theOtherIndex].value)) * 100,
  )

  if (success) {
    data[0].color = 'var(--bulma-success)'
    data[1].color = 'var(--bulma-success)'
  }
  if (fail) {
    data[0].color = 'var(--bulma-danger)'
    data[1].color = 'var(--bulma-danger)'
  }

  return (
    <Element textAlign={'center'} display="flex" justifyContent="center">
      <Element textAlign={'center'}>
        {title && (
          <Heading size={4} textAlign={'center'}>
            {title}
          </Heading>
        )}
        <Element style={{ height: 100, width: 150 }} display="inline-block">
          <ResponsivePie
            enableArcLabels={false}
            enableArcLinkLabels={false}
            colors={[data[0].color, data[1].color]}
            data={data}
            innerRadius={0.5}
            startAngle={-90}
            endAngle={90}
            margin={{ top: 15, left: 15, right: 15, bottom: 0 }}
          />
        </Element>
        <br />
        <Element>
          <Heading size={4} mb={1}>
            <Icon.Text style={{ whiteSpace: 'nowrap', display: 'block' }}>
              <Icon
                style={{ background: noData ? 'grey' : data[detailIndex].color }}
                mr={3}
                size={'small'}
              ></Icon>
              <Element renderAs="span">
                {noData ? '' : <>{percentage}%</>} {data[detailIndex].id}
              </Element>
            </Icon.Text>
          </Heading>
          {noData ? (
            <span>Pas de données</span>
          ) : (
            <>
              {data[detailIndex].value} / {data[detailIndex].value + data[theOtherIndex].value}
            </>
          )}
        </Element>
      </Element>
    </Element>
  )
}
