import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Section, Container, Box, Columns, Menu } from 'react-bulma-components'
import Layout from './layout'
import SidebarMenuItem from './sidebar/sidebar-menu-item'

interface MenuSection {
  path: string
  label: string
  icon: React.ComponentType
  badgeCount?: number
}

interface MenuGroup {
  title: string
  sections: MenuSection[]
}

interface SidebarLayoutProps<T> {
  basePath: string
  menu: {
    [groupKey: string]: MenuGroup
  }
  outletContext?: T
}

/**
 * Generic layout component for pages with a sidebar menu
 * @template T - Type of the outlet context
 */
function SidebarLayout<T>({
  basePath,
  menu,
  outletContext,
}: SidebarLayoutProps<T>): React.ReactElement {
  const location = useLocation()

  const getCurrentSection = () => {
    for (const group of Object.values(menu)) {
      const section = group.sections.find(section => location.pathname.includes(section.path))
      if (section) return section
    }
    return undefined
  }

  const currentSection = getCurrentSection()

  return (
    <Layout>
      <Section>
        <Container>
          <Columns>
            <Columns.Column size={3}>
              <Box>
                <Menu>
                  {Object.entries(menu).map(([groupKey, group]) => (
                    <Menu.List key={groupKey} title={group.title}>
                      {group.sections.map(section => (
                        <SidebarMenuItem
                          key={section.path}
                          {...section}
                          isActive={Boolean(currentSection?.path === section.path)}
                          basePath={basePath}
                        />
                      ))}
                    </Menu.List>
                  ))}
                </Menu>
              </Box>
            </Columns.Column>
            <Columns.Column size={9}>
              <Outlet context={outletContext} />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}

export default SidebarLayout
