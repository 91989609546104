import React from 'react'
import { Button, Icon } from 'react-bulma-components'
import Modal, { useModal } from '../../modal'
import { UserRoleKind } from '../../../api/users'
import { UserAddIcon } from '@heroicons/react/outline'
import UserForm from '../user-form'
import locales from '../../../../../locales'
import { useCreateMutationKey } from '../../../queries/users'
import { useMutationState } from '../../../queries'

interface InviteUserButtonProps {
  userRoleKind: UserRoleKind
}

const InviteUserButton: React.FC<InviteUserButtonProps> = ({ userRoleKind }) => {
  const createMutationStatus = useMutationState(useCreateMutationKey())
  const modal = useModal({
    autoClose: {
      on: Boolean(createMutationStatus?.status === 'success'),
      delay: 1500,
    },
  })
  const usersRoleLabel = locales.users.roles[userRoleKind]
  return (
    <>
      <Button
        onClick={() => modal.setIsDisplayed(true)}
        color="primary"
        data-test="invite-user"
        size="small"
      >
        <Icon>
          <UserAddIcon />
        </Icon>
        <span>Inviter un {usersRoleLabel}</span>
      </Button>
      <Modal title="Inviter un Nouvel Utilisateur" actions={modal}>
        <UserForm role={userRoleKind} />
      </Modal>
    </>
  )
}

export default InviteUserButton
