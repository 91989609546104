import React from 'react'
import { Element } from 'react-bulma-components'

interface EmployerRulesExplanationProps {
  textColor?: string
}

const EmployerRulesExplanation: React.FC<EmployerRulesExplanationProps> = ({ textColor }) => {
  return (
    <>
      <Element mb={2}>
        Les règles employeur permettent de paramétrer des majorations ou des primes qui seront
        automatiquement appliquées selon certaines conditions de travail :
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Majorations
        </Element>
        <Element>
          Augmentation du taux horaire (en pourcentage ou montant fixe) selon des critères comme :
          <ul>
            <li>Travail de nuit</li>
            <li>Heures supplémentaires</li>
            <li>Heures entre des plages horaires spécifiques</li>
          </ul>
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Primes
        </Element>
        <Element>
          Montant fixe attribué lorsqu'un objectif est atteint, par exemple :
          <ul>
            <li>Prime de panier</li>
            <li>Prime de transport</li>
            <li>Prime d'objectif</li>
          </ul>
        </Element>
      </Element>

      <Element>
        <Element renderAs="i" textSize={7}>
          Ces règles sont appliquées automatiquement lors du calcul des coûts de mission et
          apparaissent sur les relevés d'heures.
        </Element>
      </Element>
    </>
  )
}

export default EmployerRulesExplanation
