import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import {
  create,
  fetch,
  get,
  Terminal,
  TerminalCreationPayload,
  TerminalUpdatePayload,
  update,
} from '../api/terminals'

// Queries
export const useTerminalQuery = (
  id?: string,
  options?: QueryOptions,
): UseQueryResult<Terminal, any> => useResource<Terminal>('terminals', get, id, undefined, options)

export const useTerminalsQuery = (
  filters?: any,
  options?: QueryOptions,
): UseQueryResult<Terminal[], any> =>
  useResources<Terminal[], any>('terminals', fetch, filters, options)

// Mutations
const onMutationSuccess = () => {
  queryClient.invalidateQueries(['terminals'])
}

export const useCreateMutationKey = (): QueryKey => ['terminals', 'create']
export const useCreateMutation = (): UseMutationResult<Terminal, TerminalCreationPayload, any> =>
  useMutation(create, { mutationKey: useCreateMutationKey(), onSuccess: onMutationSuccess })

export const useUpdateMutationKey = (): QueryKey => ['terminals', 'update']
export const useUpdateMutation = (): UseMutationResult<Terminal, TerminalUpdatePayload, any> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: onMutationSuccess,
  })
