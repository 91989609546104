import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import {
  create,
  createAssociation,
  Employer,
  fetch,
  get,
  InterimAgency,
  Organization,
  OrganizationCreateAssociationPayload,
  OrganizationCreationPayload,
  OrganizationUpdateAssociationPayload,
  OrganizationUpdatePayload,
  update,
  updateAssociation,
} from '../api/organizations'
import useStore from '../store'
import { useIsAuthQueryKey } from './users'
import { compareDocsById } from '../utils/documents'

//Queries
export const useOrganizationQuery = <T = Organization>(
  id?: string,
  type?: 'interimAgency' | 'employer',
  options?: QueryOptions,
): UseQueryResult<T, any> =>
  useResource<T>(
    'organizations',
    get,
    id,
    {
      type,
    },
    {
      ...options,
      onSuccess(data) {
        const { session } = useStore.getState()
        // For Super Admin logged as organization
        // we update the currently stored organization if the fecthed is the stored one
        if (
          session.isSuperAdmin &&
          session.loggedAs?.organization?._id &&
          (data as Organization)._id === session.loggedAs.organization._id
        ) {
          session.initializeSession(
            session.currentUser,
            session.jwt,
            session.currentUserRole,
            data as Organization,
            session.currentOrganizationRole,
          )
        }
      },
    },
  )

export const useOrganizationsQuery = <T = Organization>(
  filters?: any,
  options?: QueryOptions,
): UseQueryResult<T[], any> => useResources<T[], any>('organizations', fetch, filters, options)

export const useAssociatedOrganizationsQuery = <T = Organization>(
  options?: QueryOptions,
): UseQueryResult<T[], any> => {
  const currentOrganization = useStore.getState().session.currentOrganization as Organization
  const currentOrganizationType = currentOrganization.type

  return useResources<T[], any>(
    'organizations',
    fetch,
    { type: currentOrganizationType === 'employer' ? 'interimAgency' : 'employer' },
    {
      ...(options || {}),
      select(res) {
        return res.data.filter(organization => {
          const employer = (
            currentOrganizationType === 'employer' ? currentOrganization : organization
          ) as Employer
          const interimAgency = (
            currentOrganizationType === 'interimAgency' ? currentOrganization : organization
          ) as InterimAgency
          return employer.associations.some(
            a => a.status === 'accepted' && compareDocsById(a.interimAgency, interimAgency),
          )
        })
      },
    },
  )
}

//Mutations
const onMutationSuccess = () => {
  queryClient.invalidateQueries(['users'])
  queryClient.invalidateQueries(['organizations'])
  queryClient.invalidateQueries(useIsAuthQueryKey())
}

export const useCreateMutationKey = (): QueryKey => ['organizations', 'create']
export const useCreateMutation = (): UseMutationResult<
  Organization,
  OrganizationCreationPayload,
  any
> => useMutation(create, { mutationKey: useCreateMutationKey() })

export const useUpdateMutationKey = (): QueryKey => ['organizations', 'update']
export const useUpdateMutation = (): UseMutationResult<
  Organization,
  OrganizationUpdatePayload,
  any
> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useCreateAssociationMutationKey = (): QueryKey => ['organizations', 'associations', 'create']
export const useCreateAssociationMutation = (): UseMutationResult<
  Organization,
  OrganizationCreateAssociationPayload,
  any
> =>
  useMutation(createAssociation, {
    mutationKey: useCreateAssociationMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useUpdateAssociationMutationKey = (): QueryKey => ['organizations', 'associations', 'update']
export const useUpdateAssocationMutation = (): UseMutationResult<
  Organization,
  OrganizationUpdateAssociationPayload,
  any
> =>
  useMutation(updateAssociation, {
    mutationKey: useUpdateAssociationMutationKey(),
    onSuccess: onMutationSuccess,
  })
