import React from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { formatDate, formatUtcDate } from '../../utils/date'
import { sortBy } from 'lodash'
import { ResourceLinkCell } from '../table/cells'

interface AppSubscriptionPeriodAgency {
  periodNumber: number
  year: number
  identifier: string
  from: Date
  to: Date
}

interface AppSubscriptionPeriodsAgencyListProps {
  data: AppSubscriptionPeriodAgency[]
  itemPath?: string
}

/**
 * Composant pour afficher les périodes d'abonnement d'une agence d'intérim
 * @param data - Liste des périodes d'abonnement de l'agence
 */
const AppSubscriptionPeriodsAgencyList: React.FC<AppSubscriptionPeriodsAgencyListProps> = ({
  data,
  itemPath,
}) => {
  const tableColumns = useAgencyPeriodsTableColumns(itemPath)

  return (
    <Table
      columns={tableColumns}
      data={sortBy(data, 'from').reverse()}
      noDataMessage="Aucune période d'abonnement à afficher pour cette agence"
    />
  )
}

/**
 * Hook pour définir les colonnes du tableau des périodes d'une agence
 */
const useAgencyPeriodsTableColumns = (itemPath?: string): Column<AppSubscriptionPeriodAgency>[] => {
  return React.useMemo(
    () => [
      {
        Header: 'Identifiant',
        accessor: 'identifier',
        Cell: data => (
          <ResourceLinkCell
            resource={{
              ...data.row.original,
              _id: `${data.row.original.year}_${data.row.original.periodNumber}`,
            }}
            path={itemPath || '/app-subscription-periods/'}
            label={data.row.original.identifier}
          />
        ),
      },
      {
        Header: 'Année',
        accessor: 'year',
      },
      {
        Header: 'Période',
        accessor: 'periodNumber',
      },
      {
        Header: 'Date de début',
        accessor: 'from',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Date de fin',
        accessor: 'to',
        Cell: ({ value }) => formatUtcDate(value.toString()),
      },
    ],
    [itemPath],
  )
}

export default AppSubscriptionPeriodsAgencyList
