import React from 'react'
import { Element } from 'react-bulma-components'

interface EmployerAgencyExplanationProps {
  textColor?: string
}

const EmployerAgencyExplanation: React.FC<EmployerAgencyExplanationProps> = ({ textColor }) => {
  return (
    <>
      <Element mb={2}>
        L'association entre un employeur et une agence permet de commander et d'effectuer des
        missions d'intérim. Une fois l'association validée, elle détermine :
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Missions d'Intérim
        </Element>
        <Element>
          L'employeur peut créer des demandes de mission auprès de l'agence qui pourra y affecter
          ses intérimaires. Les missions sont ensuite suivies par les deux parties.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Coefficients de Main d'Œuvre
        </Element>
        <Element>
          Pour chaque intitulé de poste, un coefficient de facturation spécifique est défini. Ce
          coefficient est utilisé pour calculer le coût des missions d'intérim.
        </Element>
      </Element>

      <Element>
        <Element renderAs="i" textSize={7}>
          Ces paramètres sont utilisés automatiquement lors de la création et du suivi des missions
          d'intérim entre l'employeur et l'agence.
        </Element>
      </Element>
    </>
  )
}

export default EmployerAgencyExplanation
