import React, { useMemo } from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { formatPreciseCost } from '../../utils/finance'
import { AppSubscriptionPeriodFees } from '../../../../backend/src/services/resources/app-subscription-periods/app-subscription-period.types'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

interface TableRow {
  weekNumber: number
  period: string
  deviceName: string
  serialNumber: string
  rentalCost: number
}

interface AppSubscriptionPeriodTerminalsTableProps {
  weeklyTerminalFees: AppSubscriptionPeriodFees['weeklyTerminalFees']
  totalTerminalFees: number
}

const AppSubscriptionPeriodTerminalsTable: React.FC<AppSubscriptionPeriodTerminalsTableProps> = ({
  weeklyTerminalFees,
  totalTerminalFees,
}) => {
  const columns = useMemo<Column<TableRow>[]>(
    () => [
      {
        Header: 'Semaine',
        accessor: 'weekNumber',
      },
      {
        Header: 'Terminal',
        accessor: 'deviceName',
      },
      {
        Header: 'N° de série',
        accessor: 'serialNumber',
      },
      {
        Header: 'Coût HT',
        accessor: 'rentalCost',
        Cell: ({ value }) => formatPreciseCost(value),
      },
    ],
    [],
  )

  const tableData = useMemo(() => {
    const detailRows = weeklyTerminalFees.flatMap(week =>
      week.terminals.map(terminal => ({
        weekNumber: week.weekNumber,
        period: `${format(new Date(week.startDate), 'dd/MM/yyyy', { locale: fr })} - ${format(
          new Date(week.endDate),
          'dd/MM/yyyy',
          { locale: fr },
        )}`,
        deviceName: terminal.deviceName,
        serialNumber: terminal.serialNumber,
        rentalCost: terminal.rentalCost,
      })),
    )

    const totalRow = {
      weekNumber: <strong>TOTAL</strong>,
      period: '',
      deviceName: '',
      serialNumber: '',
      rentalCost: totalTerminalFees,
    }

    return [...detailRows, totalRow]
  }, [weeklyTerminalFees, totalTerminalFees])

  return (
    <Table
      columns={columns}
      data={tableData}
      className="is-bordered is-striped is-narrow is-fullwidth"
      dataTest="app-subscription-period-terminals-table"
    />
  )
}

export default AppSubscriptionPeriodTerminalsTable
