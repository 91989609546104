import React from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { OutletContext } from './employer-settings-wrapper'
import ClockingRulesList from '../../components/clocking-rules/list'
import { useClockingRulesQuery } from '../../queries/clocking-rules'
import EmployerMissionsRulesListDescription from '../../components/organizations/employer-missions-rules-list-description'
import { Box, Element, Heading } from 'react-bulma-components'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import ClockingRulesExplanation from '../../components/clocking-rules/clocking-rules-explanation'
import { AddResourceIcon } from '../../components/icons'

/**
 * Component for managing employer clocking rules
 * Receives employer from outlet context and passes it to the list
 */
type ClockingRulesSettingsProps = OutletContext

const ClockingRulesSettings: React.FC = () => {
  const { employer } = useOutletContext<ClockingRulesSettingsProps>()
  const navigate = useNavigate()
  const { data: clockingRules } = useClockingRulesQuery({
    enabled: Boolean(employer),
    sort: 'title',
  })

  return (
    <>
      <SidebarContentTitle
        title="Règles de pointage"
        help={<ClockingRulesExplanation textColor={'white'} />}
        onButtonClick={() => {
          navigate('/settings/clocking-rules/new')
        }}
        addButtonLabel="Nouvelle Règle de Pointage"
        ButtonIcon={AddResourceIcon}
      />
      <Box>
        <ClockingRulesList data={clockingRules || []} itemPath={`/settings/clocking-rules/`} />
      </Box>
    </>
  )
}

export default ClockingRulesSettings
