import React from 'react'
import { Element } from 'react-bulma-components'

interface ClockingRulesExplanationProps {
  textColor?: string
}

const ClockingRulesExplanation: React.FC<ClockingRulesExplanationProps> = ({ textColor }) => {
  return (
    <>
      <Element mb={2}>
        Les règles de pointage sont associées à un intitulé de poste et définissent comment sont
        traités les pointages des employés sur ce poste. Elles permettent de paramétrer :
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Retards et Avances
        </Element>
        <Element>
          Comment sont comptabilisés les pointages en avance ou en retard par rapport à l'horaire
          prévu. Une tolérance en minutes peut être définie pour chaque cas.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Pauses
        </Element>
        <Element>
          Deux modes possibles :
          <ul>
            <li>Pause fixe : durée définie, décomptée après un certain temps de travail</li>
            <li>
              Pause pointée : l'employé pointe ses pauses, avec une tolérance possible sur la durée
              totale
            </li>
          </ul>
        </Element>
      </Element>

      <Element>
        <Element renderAs="i" textSize={7}>
          Ces règles s'appliquent automatiquement à toutes les journées de travail associées à
          l'intitulé de poste correspondant et déterminent le calcul final des heures.
        </Element>
      </Element>
    </>
  )
}

export default ClockingRulesExplanation
