import React from 'react'
import { Element } from 'react-bulma-components'

interface EmployerWorkerExplanationProps {
  textColor?: string
}

const EmployerWorkerExplanation: React.FC<EmployerWorkerExplanationProps> = ({ textColor }) => {
  return (
    <>
      <Element mb={2}>
        Les salariés internes sont directement rattachés à l'entreprise et accèdent à leurs données
        via l'application mobile TeamTim pour :
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Le Pointage
        </Element>
        <Element>
          Chaque salarié dispose d'un QR code unique dans son profil qui lui permet de pointer sur
          les bornes de l'entreprise au début et à la fin de sa journée de travail.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Horaires
        </Element>
        <Element>
          Le salarié peut consulter sur son mobile ses horaires de travail prévus et suivre ses
          pointages réels, incluant :
          <ul>
            <li>Les heures d'arrivée et de départ</li>
            <li>Les pauses</li>
            <li>Les éventuels retards ou départs anticipés</li>
          </ul>
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Les Relevés d'Heures
        </Element>
        <Element>
          Accès via l'application à l'historique des pointages et aux relevés d'heures détaillés,
          incluant les heures travaillées et les éventuelles majorations selon les règles de
          l'entreprise.
        </Element>
      </Element>

      <Element>
        <Element renderAs="i" textSize={7}>
          Les règles de pointage et les majorations sont définies par l'entreprise et s'appliquent
          automatiquement selon l'intitulé de poste du salarié.
        </Element>
      </Element>
    </>
  )
}

export default EmployerWorkerExplanation
