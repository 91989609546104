import React from 'react'
import { EmployerRule } from '../../api/employer-rules'
import { Element } from 'react-bulma-components'
import { formatDuration } from '../../utils/date'
import { getCurrentValue } from '../../utils/employer-rules'

interface EmployerRuleDescriptionProps {
  employerRule: EmployerRule
}

// Composants réutilisables pour les patterns communs
const WeeklyRangeText: React.FC<{ min: number; max?: number }> = ({ min, max }) =>
  max !== undefined ? (
    <>
      {' '}
      entre {formatDuration(min)} et {formatDuration(max)} par semaine
    </>
  ) : (
    <> au-delà de {formatDuration(min)} par semaine</>
  )

const DailyRangeText: React.FC<{ min: number; max?: number }> = ({ min, max }) =>
  max !== undefined ? (
    <>
      {' '}
      entre {formatDuration(min)} et {formatDuration(max)}
    </>
  ) : (
    <> après {formatDuration(min)}</>
  )

const CostPrefix: React.FC<{ value: number | undefined; type: 'multiply' | 'add' }> = ({
  value,
  type,
}) => {
  if (!value) return null
  return (
    <>
      Le coût horaire est {type === 'multiply' ? `multiplié par ${value}` : `augmenté de ${value}€`}
    </>
  )
}

const GoalDescription: React.FC<EmployerRuleDescriptionProps> = ({ employerRule }) => {
  const { interval } = employerRule
  const { period, min } = interval
  const value = getCurrentValue(employerRule.valuesInTime)

  return (
    <>
      Une prime de {value}€ est attribuée
      {period === 'week' ? ' par semaine' : ' chaque jour'} à partir de {formatDuration(min)} de
      travail de nuit
    </>
  )
}

const MultiplyDescription: React.FC<EmployerRuleDescriptionProps> = ({ employerRule }) => {
  const { interval } = employerRule
  const { period, mode, min, max } = interval
  const value = getCurrentValue(employerRule.valuesInTime)

  if (period === 'day' && mode === 'timeRange') {
    return (
      <>
        <CostPrefix value={value} type="multiply" /> entre {formatDuration(min)} et{' '}
        {formatDuration(max ?? 0)}
      </>
    )
  }

  if (period === 'week') {
    if (min === 0) {
      return (
        <>
          <CostPrefix value={value} type="multiply" /> pour les heures de nuit comptabilisées sur la
          semaine
        </>
      )
    }
    return (
      <>
        <CostPrefix value={value} type="multiply" /> pour les heures travaillées
        <WeeklyRangeText min={min} max={max} />
      </>
    )
  }

  return (
    <>
      <CostPrefix value={value} type="multiply" /> pour les heures de nuit
      <DailyRangeText min={min} max={max} />
    </>
  )
}

const AddDescription: React.FC<EmployerRuleDescriptionProps> = ({ employerRule }) => {
  const { interval } = employerRule
  const { period, mode, min, max } = interval
  const value = getCurrentValue(employerRule.valuesInTime)

  if (period === 'day' && mode === 'timeRange') {
    return (
      <>
        <CostPrefix value={value} type="add" /> entre {formatDuration(min ?? 0)} et{' '}
        {formatDuration(max ?? 0)} chaque jour
      </>
    )
  }

  if (period === 'week') {
    if (min === 0) {
      return (
        <>
          <CostPrefix value={value} type="add" /> pour les heures de nuit de la semaine
        </>
      )
    }
    return (
      <>
        <CostPrefix value={value} type="add" /> pour les heures travaillées
        <WeeklyRangeText min={min} max={max} />
      </>
    )
  }

  return (
    <>
      <CostPrefix value={value} type="add" /> pour les heures de nuit
      <DailyRangeText min={min} max={max} />
    </>
  )
}

const EmployerRuleDescription: React.FC<EmployerRuleDescriptionProps> = ({ employerRule }) => {
  const DescriptionComponent = {
    multiply: MultiplyDescription,
    goal: GoalDescription,
    add: AddDescription,
  }[employerRule.kind]

  return (
    <Element>
      <DescriptionComponent employerRule={employerRule} />
    </Element>
  )
}

export default EmployerRuleDescription
