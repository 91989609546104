import React, { useState } from 'react'
import { Button, Container, Element, Form, Icon, Section } from 'react-bulma-components'
import { DocumentAddIcon } from '@heroicons/react/solid'
import Layout from '../../components/layout/layout'
import ComponentHeader from '../../components/sections/component-header'
import PageHeading from '../../components/sections/page-heading'
import { useTerminalsQuery } from '../../queries/terminals'
import { Terminal } from '../../api/terminals'
import Modal, { useModal } from '../../components/modal'
import TerminalForm from '../../components/terminals/terminal-form'
import TerminalsList from '../../components/terminals/list'

const TerminalsListPage: React.FC = () => {
  const terminalsQuery = useTerminalsQuery()
  const [currentTerminal, setCurrentTerminal] = useState<Terminal | null>(null)
  const newTerminalModal = useModal()

  React.useEffect(() => {
    newTerminalModal.setIsDisplayed(Boolean(currentTerminal))
  }, [currentTerminal])

  React.useEffect(() => {
    if (!newTerminalModal.isDisplayed) {
      setCurrentTerminal(null)
    }
  }, [newTerminalModal.isDisplayed])

  return (
    <Layout>
      <PageHeading title="Liste des Bornes de Pointage" />
      <Section>
        <Container>
          <ComponentHeader>
            <ComponentHeader.Right>
              <Form.Field>
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control>
                  <Button
                    onClick={() => {
                      setCurrentTerminal(null)
                      newTerminalModal.setIsDisplayed(true)
                    }}
                    outlined
                    color="primary"
                  >
                    <Icon>
                      <DocumentAddIcon />
                    </Icon>
                    <span>Nouveau terminal</span>
                  </Button>
                  <Element></Element>
                </Form.Control>
              </Form.Field>
            </ComponentHeader.Right>
          </ComponentHeader>

          <Modal
            title={
              currentTerminal ? 'Modifier une Borne de Pointage' : 'Créer une Borne de Pointage'
            }
            actions={newTerminalModal}
          >
            <TerminalForm terminal={currentTerminal || undefined} />
          </Modal>

          <TerminalsList data={terminalsQuery.data || []} onTerminalClick={setCurrentTerminal} />
        </Container>
      </Section>
    </Layout>
  )
}

export default TerminalsListPage
