import React from 'react'
import { Column, Row } from 'react-table'
import Table from '../table'
import { Link } from 'react-router-dom'
import { ClockingRules } from '../../api/clocking-rules'
import ClockingRulesDescription from './clocking-rules-description'
import { trimTextIfLongerThan } from '../../utils/texts'
import ClockingRulesBreakDescription from './clocking-rules-break-description'

interface ClockingRulesListProps {
  data: ClockingRules[]
  itemPath: string
}

const ClockingRulesList: React.FC<ClockingRulesListProps> = ({ data, itemPath }) => {
  const tableColumns = useListTableColumns(data, itemPath)

  return (
    <Table<ClockingRules>
      columns={tableColumns}
      data={data}
      noDataMessage="Aucune Règle de Pointage à afficher"
    />
  )
}

export default ClockingRulesList

const useListTableColumns = (
  clockingRules: ClockingRules[],
  itemPath: string,
): Column<ClockingRules>[] =>
  React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: ({ row }: { row: Row<ClockingRules> }) => {
          const { original: clockingRules } = row
          return (
            <p>
              <b>
                <Link to={`${itemPath}${clockingRules._id}`}>{clockingRules.name}</Link>
              </b>
              {clockingRules?.description && (
                <>
                  <br />
                  {trimTextIfLongerThan(clockingRules.description, 120)}
                </>
              )}
            </p>
          )
        },
      },
      {
        Header: 'En Début de Journée',
        accessor: row => row.rules.start,
        Cell: ({ row }: { row: Row<ClockingRules> }) => {
          const { original: clockingRules } = row
          return <ClockingRulesDescription clockingRules={clockingRules} type="start" small />
        },
      },
      {
        Header: 'En Fin de Journée',
        accessor: row => row.rules.start,
        Cell: ({ row }: { row: Row<ClockingRules> }) => {
          const { original: clockingRules } = row
          return <ClockingRulesDescription clockingRules={clockingRules} type="end" small />
        },
      },
      {
        Header: 'Pause',
        accessor: row => row.break,
        Cell: ({ row }: { row: Row<ClockingRules> }) => {
          const { original: clockingRules } = row
          return <ClockingRulesBreakDescription clockingRules={clockingRules} small />
        },
      },
    ],
    [clockingRules, itemPath],
  )
