import React, { useMemo } from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { formatPreciseCost } from '../../utils/finance'
import { AppSubscriptionPeriodsInterimAgencyTotal } from '../../api/app-subscription-periods'
import { Element } from 'react-bulma-components'
import { Employer } from '../../api/organizations'

interface TableRow {
  name: React.ReactNode
  workPeriods: number
  uniqueWorkers: number
  withoutClockings: number
  licenseFees: number
  workPeriodsFees: number
  terminalFees: number
  adjustments: number
  total: number
}

interface AgencyTotalsTableProps {
  totals: AppSubscriptionPeriodsInterimAgencyTotal
  onEmployerClick: (employer: Employer) => void
}

/**
 * Tableau détaillant les totaux pour une agence d'intérim
 */
const AgencyTotalsTable: React.FC<AgencyTotalsTableProps> = ({ totals, onEmployerClick }) => {
  const columns = useMemo<Column<TableRow>[]>(
    () => [
      {
        Header: 'Employeur',
        accessor: 'name',
      },
      {
        Header: 'Journées de travail',
        accessor: 'workPeriods',
      },
      {
        Header: 'Frais de licence',
        accessor: 'licenseFees',
        Cell: ({ value }) => formatPreciseCost(value),
      },
      {
        Header: 'Frais périodes',
        accessor: 'workPeriodsFees',
        Cell: ({ value }) => formatPreciseCost(value),
      },
      {
        Header: 'Frais terminaux',
        accessor: 'terminalFees',
        Cell: ({ value }) => formatPreciseCost(value),
      },
      {
        Header: 'Total HT',
        accessor: 'total',
        Cell: ({ value }) => formatPreciseCost(value),
      },
    ],
    [],
  )

  const tableData = useMemo(() => {
    const employerRows: TableRow[] = totals.employerDetails.map(detail => ({
      name: (
        <Element
          renderAs="a"
          onClick={() => onEmployerClick && onEmployerClick(detail.employer as Employer)}
        >
          {(detail.employer as Employer).name}
        </Element>
      ),
      workPeriods: detail.metrics.workPeriodsCount,
      uniqueWorkers: detail.metrics.uniqueWorkersCount,
      withoutClockings: detail.metrics.workPeriodsWithoutClockingsCount,
      licenseFees: detail.fees.discountedLicenseFees,
      workPeriodsFees: detail.fees.discountedWorkPeriodsFees,
      terminalFees: detail.fees.discountedTerminalFees,
      adjustments: detail.fees.adjustmentsAmount,
      total: detail.fees.total,
    }))

    // Ligne des totaux
    const totalRow: TableRow = {
      name: 'Total',
      workPeriods: totals.totalWorkPeriods,
      uniqueWorkers: totals.totalUniqueWorkers,
      withoutClockings: totals.totalWorkPeriodsWithoutClockings,
      licenseFees: totals.totalLicenseFees,
      workPeriodsFees: totals.totalDiscountedWorkPeriodsFees,
      terminalFees: totals.totalDiscountedTerminalFees,
      adjustments: totals.totalAdjustments,
      total: totals.totalAmount,
    }

    return [...employerRows, totalRow]
  }, [totals])

  return (
    <Table
      columns={columns}
      data={tableData}
      className="is-bordered"
      noDataMessage="Aucun total à afficher"
    />
  )
}

export default AgencyTotalsTable
