import React from 'react'
import { Button, Element, Icon } from 'react-bulma-components'
import { useModal } from '../../modal'
import { UserAddIcon } from '@heroicons/react/outline'
import OfflineUserFormModal from '../modals/offline-user-form'
import { useCreateMutationKey } from '../../../queries/users'
import { useMutationState } from '../../../queries'
import WorkerLabel from '../../texts/worker-label'

const CreateOfflineWorkerButton: React.FC = ({}) => {
  const createMutationStatus = useMutationState(useCreateMutationKey())
  const modal = useModal({
    autoClose: {
      on: Boolean(createMutationStatus?.status === 'success'),
      delay: 1500,
    },
  })
  return (
    <>
      <Button
        onClick={() => modal.setIsDisplayed(true)}
        color="primary"
        data-test="invite-offline-user"
        className="mr-2"
        size="small"
      >
        <Icon>
          <UserAddIcon />
        </Icon>
        <Element renderAs="span">
          Créer un <WorkerLabel /> <Element renderAs="strong">Offline</Element>
        </Element>
      </Button>
      <OfflineUserFormModal modalActions={modal} />
    </>
  )
}

export default CreateOfflineWorkerButton
