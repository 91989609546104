import React from 'react'
import { useFirstRender } from '../../../hooks/use-first-render'
import { FormikErrors } from 'formik'

interface AutoSubmitProps {
  values: any
  errors?: FormikErrors<object>
  submitForm: () => void
}

const AutoSubmit: React.FunctionComponent<AutoSubmitProps> = ({ values, errors, submitForm }) => {
  const firstRender = useFirstRender()

  React.useEffect(() => {
    if (errors && Object.keys(errors).length > 0) return
    if (!firstRender) submitForm()
  }, [values, submitForm])

  return null
}

export default AutoSubmit
