import React from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { ResourceLinkCell } from '../table/cells'
import { JobTitle } from '../../api/job-titles'
import { InterimAgency } from '../../api/organizations'
import { Button, Icon } from 'react-bulma-components'
import { EditIcon } from '../icons'

interface LaborCostsCoefficientsTableProps {
  jobTitles: JobTitle[]
  interimAgency: InterimAgency
  onEdit?: (
    jobTitleId: string,
    agencyId: string,
    laborCostCoefficient: JobTitle['laborCostCoefficients'][0],
  ) => void
}

const LaborCostsCoefficientsTable: React.FC<LaborCostsCoefficientsTableProps> = ({
  jobTitles,
  interimAgency,
  onEdit,
}) => {
  const tableColumns = useTableColumns(interimAgency, onEdit)

  const tableData = jobTitles.map(jobTitle => {
    const coefficient = jobTitle.laborCostCoefficients.find(
      c => c.interimAgency === interimAgency._id,
    )

    return {
      jobTitle,
      _id: jobTitle._id,
      managementValue: coefficient?.managementValue ?? '-',
      delegationValue: coefficient?.delegationValue ?? '-',
    }
  })

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      noDataMessage="Aucun intitulé de poste à afficher"
    />
  )
}

const useTableColumns = (
  interimAgency: InterimAgency,
  onEdit?: LaborCostsCoefficientsTableProps['onEdit'],
): Column[] => {
  return React.useMemo(
    () =>
      [
        {
          Header: `Intitulé de poste / ${interimAgency.name}`,
          accessor: 'jobTitle',
          Cell: ({ value: jobTitle }) => jobTitle.name,
        },
        {
          Header: 'Délégation',
          accessor: 'delegationValue',
          className: 'has-text-centered',
        },
        {
          Header: 'Gestion',
          accessor: 'managementValue',
          className: 'has-text-centered',
        },
        onEdit && {
          Header: '',
          id: 'actions',
          accessor: 'jobTitle',
          Cell: ({ value: jobTitle, row }) => (
            <Button
              onClick={() => onEdit?.(jobTitle._id, interimAgency._id, row.original as any)}
              color="primary"
              outlined
              size="small"
            >
              <Icon>
                <EditIcon className="h-5 w-5" />
              </Icon>
            </Button>
          ),
          className: 'has-text-right',
        },
      ].filter(Boolean),
    [interimAgency, onEdit],
  )
}

export default LaborCostsCoefficientsTable
