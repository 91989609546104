import { FieldProps } from 'formik'
import React from 'react'
import { Form } from 'react-bulma-components'
import FormField, { FormFieldProps } from './form-field'

interface InputFieldProps extends FormFieldProps, FieldProps {
  type: string
}

const InputField: React.FunctionComponent<InputFieldProps> = props => {
  const { field, form, label, help, ...rest } = props
  if (props.field.value === undefined) props.field.value = ''
  const { required } = rest

  return (
    <FormField
      label={label}
      help={help}
      error={form.errors[field.name] as string}
      required={required}
    >
      <Form.Input
        {...field}
        {...rest}
        onWheel={e => rest.type === 'number' && e.currentTarget.blur()}
      />
    </FormField>
  )
}

export default InputField
