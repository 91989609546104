import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { Employer } from '../../api/organizations'
import EmployerForm from '../../components/organizations/employer-form'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import { Box, Element } from 'react-bulma-components'
import EmployerMissionsRulesListDescription from '../../components/organizations/employer-missions-rules-list-description'

interface EmployerInfoSettingsProps {
  employer?: Employer
}

const EmployerInfoSettings: React.FC = () => {
  const { employer } = useOutletContext<EmployerInfoSettingsProps>()

  return (
    <>
      <SidebarContentTitle title="Informations de l'entreprise" />
      <Box>
        <EmployerForm organization={employer} />
      </Box>
      <SidebarContentTitle title="Règles internes" />
      <Box>
        <EmployerMissionsRulesListDescription rules={employer?.rules.missions} />
        <Element renderAs="p" textSize={7} textColor="grey">
          Pour toute modification des règles internes, veuillez contacter votre administrateur
          TeamTim.
        </Element>
      </Box>
    </>
  )
}

export default EmployerInfoSettings
