import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, UseMutationResult, useResource, useResources } from '.'
import {
  ClockingRules,
  ClockingRulesCreationPayload,
  fetch,
  create,
  update,
  ClockingRulesUpdatePayload,
  get,
} from '../api/clocking-rules'

//Queries
export const useClockingRulesQuery = (
  //filters?: FetchClockingsParams,
  options?: { refetchInterval?: number; enabled?: boolean },
): UseQueryResult<ClockingRules[] /* , FetchClockingsParams */> =>
  useResources('clocking-rules', fetch, /* filters || */ {}, options)
export const useOneClockingRulesQuery = (
  id?: ClockingRules['_id'],
  options?: { refetchInterval?: number; enabled?: boolean },
): UseQueryResult<ClockingRules /* , FetchClockingsParams */> =>
  useResource('clocking-rules', get, id, undefined, options)

const resourceQueryKey = 'clocking-rules'

//Mutations
export const useCreateMutationKey = (): QueryKey => [resourceQueryKey, 'create']
export const useUpdateMutationKey = (): QueryKey => [resourceQueryKey, 'update']
export const useCreateMutation = (): UseMutationResult<
  ClockingRules,
  ClockingRulesCreationPayload,
  any
> =>
  useMutation(create, {
    mutationKey: useCreateMutationKey(),
    onSuccess: () => {
      queryClient.invalidateQueries([resourceQueryKey])
    },
  })
export const useUpdateMutation = (): UseMutationResult<
  ClockingRules,
  ClockingRulesUpdatePayload,
  any
> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: () => {
      queryClient.invalidateQueries([resourceQueryKey])
    },
  })
