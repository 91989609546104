import React from 'react'
import { Box, Button, Container, Element, Icon } from 'react-bulma-components'
import { useOrganizationsQuery } from '../../queries/organizations'
import { Employer } from '../../api/organizations'
import AssociationsTable from '../../components/interim-agencies/associations-table'
import { useOutletContext } from 'react-router-dom'
import Modal, { useModal } from '../../components/modal'
import AssociationForm from '../../components/organizations/association-form'
import { DocumentAddIcon } from '@heroicons/react/outline'
import { OutletContext } from './interim-agency-settings-wrapper'
import Protected, { isAuthorizedFor } from '../../components/protected/protected'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import EmployerAgencyExplanation from '../../components/organizations/employer-agency-explanation'

type AgencySubscriptionFormProps = OutletContext

const PartnerEmployers: React.FC = () => {
  const { interimAgency } = useOutletContext<AgencySubscriptionFormProps>()
  const organizationsQuery = useOrganizationsQuery<Employer>(
    {
      type: 'employer',
    },
    { enabled: isAuthorizedFor(['superAdmin']) },
  )

  const associatiedEmployersQuery = useOrganizationsQuery<Employer>({
    type: 'employer',
    'associations.interimAgency': interimAgency?._id,
  })

  const associatiedEmployers = associatiedEmployersQuery.data || []

  const inviteEmployerModal = useModal()

  const employers = organizationsQuery.data || []

  return (
    <Container>
      <Protected roles={['superAdmin']}>
        <Element className="is-flex is-justify-content-flex-end">
          <Button
            outlined
            color="primary"
            onClick={() => inviteEmployerModal.setIsDisplayed(true)}
            mr={2}
            size="small"
          >
            <Icon>
              <DocumentAddIcon />
            </Icon>
            <span>Ajouter un Employeur Paternaire</span>
          </Button>
        </Element>
      </Protected>
      <SidebarContentTitle
        title="Entreprises Utilisatrices Partenaires"
        help={<EmployerAgencyExplanation textColor={'white'} />}
      />
      <Box>
        {interimAgency && (
          <AssociationsTable
            organizations={associatiedEmployers}
            interimAgency={interimAgency}
            itemPath={`/settings/partner-employers/`}
          />
        )}
      </Box>
      {interimAgency && (
        <Modal title="Inviter un Nouvel Employeur" actions={inviteEmployerModal}>
          <AssociationForm employers={employers} interimAgency={interimAgency} />
        </Modal>
      )}
    </Container>
  )
}

export default PartnerEmployers
