import React from 'react'
import { Element } from 'react-bulma-components'

interface WorkPeriodEventInformativeDescriptionProps {
  textColor?: string
}

const WorkPeriodEventInformativeDescription: React.FC<
  WorkPeriodEventInformativeDescriptionProps
> = ({ textColor }) => {
  return (
    <>
      <Element renderAs="strong" textColor={textColor}>
        Evénement Informatif
      </Element>{' '}
      : il est indicatif, il n’a pas d’incidence sur des calculs. <br />
      <Element renderAs="i">Exemple : Une Absence Autorisée, un Grand Déplacement</Element>
    </>
  )
}

export default WorkPeriodEventInformativeDescription
