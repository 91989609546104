import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './employer-settings-wrapper'
import { Box } from 'react-bulma-components'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import {
  useCreateMutationKey,
  useJobTitleQuery,
  useUpdateMutationKey,
} from '../../queries/job-titles'
import JobTitleForm from '../../components/job-titles/job-title-form'
import JobTitleExplanation from '../../components/job-titles/job-title-explanation'
import useUrlId from '../../hooks/use-url-id'
import FormWrapper from '../../components/form/form-wrapper'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import { useMutationState } from '../../queries'

/**
 * Composant pour afficher et éditer les détails d'un intitulé de poste
 */
const JobTitleDetails: React.FC = () => {
  //const { employer } = useOutletContext<OutletContext>()
  const { id, isNew } = useUrlId()

  const { data: jobTitle } = useJobTitleQuery(id, {
    enabled: Boolean(id),
  })

  const mutationState = useMutationState(isNew ? useCreateMutationKey() : useUpdateMutationKey())
  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    //@ts-ignore
    mutationState,
    !isNew,
  )

  return (
    <>
      <SidebarContentTitle
        title={
          isNew ? 'Nouvel intitulé de poste' : `Détails de l\'intitulé de poste ${jobTitle?.name}`
        }
        help={<JobTitleExplanation textColor={'white'} />}
      />
      <FormWrapper {...formWrapperProps}>
        <Box>
          <JobTitleForm jobTitle={jobTitle} />
        </Box>
      </FormWrapper>
    </>
  )
}

export default JobTitleDetails

const formWrapperBaseProps = {
  loadingProps: { body: "Enregistrement de l'Intitulé de Poste..." },
  successProps: {
    title: 'Intitulé de Poste créé',
    body: {
      description: "L'Intitulé de Poste a été créé",
      actions: [
        {
          label: 'Nouvel Intitulé de Poste',
        },
        {
          label: 'Retourner à la liste',
          to: '/settings/job-titles',
        },
      ],
    },
  },
}
