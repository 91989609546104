import React from 'react'
import { Column } from 'react-table'
import { Link } from 'react-router-dom'
import { Employer, InterimAgency, OrganizationTypeEnum } from '../../api/organizations'
import Table from '../table'
import RoleStatus from '../users/role-status'
import Avatar from '../avatar/avatar'
import { Button } from 'react-bulma-components'
import Protected from '../protected/protected'
import { EmployerAssociation } from '../../../../backend/src/services/resources/organizations/organization.model'
import { sortBy } from 'lodash'

interface OrganizationAssociationsListProps {
  // Les associations à afficher
  associations: EmployerAssociation[]
  // Le type d'organisation qu'on affiche ('employer' ou 'agency')
  viewType: OrganizationTypeEnum
  // Callback optionnel pour gérer l'acceptation d'une invitation
  onAcceptInvitation?: (associationId: string) => void
}

/**
 * Composant générique pour afficher les associations entre organisations
 * @param associations - Liste des associations à afficher
 * @param viewType - Perspective de vue (employer pour voir les agences, agency pour voir les employeurs)
 * @param onAcceptInvitation - Callback optionnel pour accepter une invitation
 */
const OrganizationAssociationsList: React.FC<OrganizationAssociationsListProps> = ({
  associations,
  viewType,
  onAcceptInvitation,
}) => {
  const tableColumns = useAssociationsListColumns({ viewType, onAcceptInvitation })

  return (
    <Table
      columns={tableColumns}
      data={sortBy(associations, 'interimAgency.name')}
      noDataMessage={`Aucun${
        viewType === OrganizationTypeEnum.employer ? 'e agence' : ' employeur'
      } à afficher`}
    />
  )
}

const useAssociationsListColumns = ({
  viewType,
  onAcceptInvitation,
}: Omit<OrganizationAssociationsListProps, 'associations'>): Column<EmployerAssociation>[] => {
  return React.useMemo(
    () => [
      {
        Header: viewType === OrganizationTypeEnum.employer ? "Agence d'intérim" : 'Employeur',
        accessor:
          viewType === OrganizationTypeEnum.employer ? 'interimAgency.name' : 'employer.name',
        Cell: (data: any) => {
          const association = data.row.original
          const organization =
            viewType === OrganizationTypeEnum.employer
              ? association.interimAgency
              : association.employer

          return (
            <>
              <Protected
                roles={[
                  viewType === OrganizationTypeEnum.employer
                    ? 'interimAgencyMember'
                    : 'employerMember',
                ]}
              >
                {association.status === 'accepted' && (
                  <Link
                    to={`/${
                      viewType === OrganizationTypeEnum.employer ? 'agencies' : 'employers'
                    }/${organization._id}`}
                  >
                    {organization.name}
                  </Link>
                )}
              </Protected>
              <Protected
                roles={[
                  viewType === OrganizationTypeEnum.employer
                    ? 'employerMember'
                    : 'interimAgencyMember',
                ]}
              >
                {organization.name}
              </Protected>
            </>
          )
        },
      },
      {
        Header: "Statut de l'invitation",
        accessor: 'status',
        Cell: (data: any) => <RoleStatus role={data.row.original} showDate />,
      },
      {
        Header: 'Invité par',
        accessor: 'invitedBy',
        Cell: (data: any) => {
          const user = data.row.original.invitedBy
          return user ? <Avatar user={user} /> : null
        },
      },
      {
        Header: 'Actions',
        accessor: '__actions',
        Cell: (data: any) => {
          const association = data.row.original
          if (association.status === 'pending' && onAcceptInvitation) {
            return (
              <Button
                size="small"
                color="success"
                onClick={() => onAcceptInvitation(association._id as string)}
              >
                Accepter
              </Button>
            )
          }
          return null
        },
      },
    ],
    [viewType, onAcceptInvitation],
  )
}

export default OrganizationAssociationsList
