import React from 'react'
import { Column } from 'react-table'
import { WorkPeriodEvent } from '../../api/work-period-events'
import Table from '../table'
import { RequestButton } from '../request-components/request-components'
import { MutationState } from '../../queries'
import locales from '../../../../locales/work-period-events'

interface WorkPeriodEventsListProps {
  workPeriodEvents: WorkPeriodEvent[]
  onEventClick?: (workPeriodEvent: WorkPeriodEvent) => void
  mutation?: MutationState
}

/**
 * Displays a table of work period events with their code, title and type
 */
const WorkPeriodEventsList: React.FC<WorkPeriodEventsListProps> = ({
  workPeriodEvents,
  onEventClick,
  mutation,
}) => {
  const tableColumns = useWorkPeriodEventsColumns(workPeriodEvents, onEventClick, mutation)

  return (
    <Table<WorkPeriodEvent>
      columns={tableColumns}
      data={workPeriodEvents}
      noDataMessage="Aucune donnée"
    />
  )
}

const useWorkPeriodEventsColumns = (
  workPeriodEvents: WorkPeriodEvent[],
  onClick?: (workPeriodEvent: WorkPeriodEvent) => void,
  mutation?: MutationState,
): Column<WorkPeriodEvent>[] =>
  React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Intitulé',
        accessor: 'title',
        Cell: (data: any) => {
          const workPeriodEvent: WorkPeriodEvent = data.cell.row.original
          return (
            <RequestButton
              color="ghost"
              onClick={() => onClick?.(workPeriodEvent)}
              size={'small'}
              //@ts-ignore
              mutation={mutation?.data?.data?._id === workPeriodEvent._id ? mutation : undefined}
            >
              {workPeriodEvent.title}
            </RequestButton>
          )
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (data: any) => {
          const workPeriodEvent: WorkPeriodEvent = data.cell.row.original
          return locales.types[workPeriodEvent.type]
        },
      },
    ],
    [workPeriodEvents, mutation],
  )

export default WorkPeriodEventsList
