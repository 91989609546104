//TODO: To be transformed into namespaces when using an i18n lib

export default {
  users: {
    roles: {
      superAdmin: 'Super Admin',
      worker: 'Travailleur',
      interimAgencyMember: "Gestionnaire d'Agence",
      employerMember: "Gestionnaire d'Employeur",
    },
  },
  organizations: {
    type: {
      interimAgency: "Agence d'Intérim",
      employer: 'Employeur',
    },
  },
  clockings: {
    theoritical: "L'Heure Théorique",
    clocking: 'Le Pointage',
  },
  resources: {
    missions: 'Missions',
    workersRequests: 'Demandes de Personnel',
    preBillings: 'Pré-facturations',
    clockings: 'Pointages',
  },
}
