import React from 'react'
import { Element, Heading, Button, Icon, Message } from 'react-bulma-components'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip'

interface SidebarContentTitleProps {
  title: string
  onButtonClick?: () => void
  addButtonLabel?: string
  buttonDataTestId?: string
  ButtonIcon?: React.ComponentType
  help?: React.ReactNode
  ActionButton?: React.ReactNode
}

/**
 * Composant réutilisable pour les en-têtes de page avec titre et bouton d'action optionnel
 * @param title - Titre de la page
 * @param onButtonClick - Fonction appelée lors du clic sur le bouton d'ajout
 * @param addButtonLabel - Texte du bouton d'ajout
 * @param ButtonIcon - Composant icône à afficher dans le bouton
 * @param help - Contenu d'aide à afficher dans une infobulle
 */
const SidebarContentTitle: React.FC<SidebarContentTitleProps> = ({
  title,
  onButtonClick,
  addButtonLabel,
  ButtonIcon,
  ActionButton,
  help,
  buttonDataTestId,
}) => {
  const tooltipId = `help-tooltip-${title}`

  return (
    <Element display="flex" justifyContent="space-between" alignItems="center" mb={4}>
      <Element display="flex" alignItems="center">
        <Heading size={5} style={{ marginBottom: 0 }}>
          {title}
        </Heading>
        {help && (
          <>
            <Icon ml={2} style={{ cursor: 'help' }} data-tip data-for={tooltipId}>
              <QuestionMarkCircleIcon width={20} />
            </Icon>
            <ReactTooltip
              id={tooltipId}
              effect="solid"
              place="right"
              className="custom-tooltip-width"
            >
              <Message.Body>{help}</Message.Body>
            </ReactTooltip>
          </>
        )}
      </Element>
      {ActionButton}
      {onButtonClick && (
        <Button color="primary" onClick={onButtonClick} size="small" data-test={buttonDataTestId}>
          {ButtonIcon && (
            <Icon>
              <ButtonIcon />
            </Icon>
          )}
          <Element renderAs="span">{addButtonLabel}</Element>
        </Button>
      )}
    </Element>
  )
}

export default SidebarContentTitle
