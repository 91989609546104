import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import {
  Button,
  Columns,
  Element,
  Hero,
  Icon,
  Level,
  Message,
  Section,
  Tabs,
  Tag,
} from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import { Field, Form, Formik, FormikProps } from 'formik'
import AutoSubmit from '../../components/form/utils/auto-submit'
import { RefreshIcon } from '@heroicons/react/solid'
import { useCheckAppVersionQuery, useCreateMutation } from '../../queries/clockings'
import { CheckCircleIcon } from '@heroicons/react/outline'
import QrScannerField from '../../components/form/fields/qr-scanner'
import { CountBack, CountBackAndHide } from '../../hooks/use-count-back'
//@ts-expect-error -> this is an mp3 file !
import * as BeepFile from '../../images/beep.mp3'
import useStore from '../../store'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { differenceInSeconds } from 'date-fns'
import { formatTime } from '../../utils/date'
//@ts-ignore
import * as logo from '../../images/logo.webp'

const beep = new Audio(BeepFile)
const usePlayBeep = () => {
  useEffect(() => {
    beep.play()
    return () => {
      beep.pause()
    }
  }, [])
}

const Beep = () => {
  usePlayBeep()
  return <></>
}

const StatusTag = () => {
  const checkAppVersionQuery = useCheckAppVersionQuery(60000)

  const isOnline = checkAppVersionQuery.dataUpdatedAt > 0 && checkAppVersionQuery.isFetched

  const statusText = isOnline ? 'online' : 'offline'
  const statusColor = isOnline ? 'success' : 'danger'

  return (
    <Tag size="small" color={statusColor}>
      {statusText}
    </Tag>
  )
}

const QrScannerPage: FC<{ scanner: 'camera' | 'laser' }> = ({ scanner }) => {
  const navigate = useNavigate()
  const mutation = useCreateMutation()
  const [qrReaderError, setQrReaderError] = useState<string>()
  const currentOrganization = useStore(s => s.session.currentOrganization)
  const currentUser = useStore(s => s.session.currentUser)
  const currentUserRole = useStore(s => s.session.currentUserRole)
  const logout = useStore(s => s.session.logout)
  const [searchParams] = useSearchParams()
  // @ts-expect-error -> fix this
  const isSystemUser = currentUser?.appAccess === 'system'

  const [camera, setCamera] = React.useState<'user' | 'environment' | undefined>('environment')
  const [mode, setMode] = React.useState<'kiosk' | 'mobile' | undefined>('kiosk')
  const [lastQrSent, setLastQrSent] = React.useState<{
    value: string
    time: number
  }>({
    value: '',
    time: 0,
  })

  if (isSystemUser) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        if (registrations.length > 0) {
          console.info('Service Worker already registered')
        } else {
          navigator.serviceWorker
            .register(new URL('service-worker.js', import.meta.url))
            .then(() => {
              console.info('Service Worker registered')
            })
            .catch(error => {
              console.error('Service Worker registration failed:', error)
            })
        }
      })
    }
  }

  useEffect(() => {
    if (!isSystemUser) return
    const onlyInteractiveOrButtonClicks = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && event.target.closest('button')) {
        return // Do nothing and let the event continue
      }
      event.preventDefault()
      event.stopImmediatePropagation()
    }
    const noClicks = (e: Event) => {
      e.preventDefault()
      e.stopImmediatePropagation()
    }

    document.addEventListener('click', onlyInteractiveOrButtonClicks)
    document.addEventListener('dragstart', noClicks)
    document.addEventListener('selectstart', noClicks)

    return () => {
      document.removeEventListener('click', onlyInteractiveOrButtonClicks)
      document.removeEventListener('dragstart', noClicks)
      document.removeEventListener('selectstart', noClicks)
    }
  }, [isSystemUser]) // Empty dependency array to run the effect only once when the component mounts

  if (currentUserRole === 'superAdmin') {
    return (
      <Layout>
        <Section>Vous devez être connecté en tant qu'Employeur pour pouvoir scanner</Section>
      </Layout>
    )
  }

  // Remove this later, we still support /qr-scanner?laser=true url scheme
  // Has been replaced with /kiosk and /laser-scanner
  if (scanner === 'camera' && searchParams.get('laser')) {
    return <Navigate to="/laser-scanner" />
  }

  const clockingResultColor =
    mutation.isSuccess && mutation.data.data.mission
      ? 'success'
      : mutation.isIdle
      ? 'white'
      : mutation.isError || (mutation.isSuccess && !mutation.data.data.mission) || qrReaderError
      ? 'danger'
      : 'white'

  /**
   * When logged in as a system user
   * Admins have to click exit button -> logo -> exit button -> logo -> exit button in a row
   * To validate logout
   * This prevents abuse from kiosk users
   */
  const secureExitClicked = [false, false, false, false]
  const handleExitScannerPage = (from: 'exit-button' | 'logo') => {
    if (isSystemUser) {
      if (secureExitClicked.every(value => value === true)) {
        // The sequence is okay, exit
        logout()
      }
      const currentStep = secureExitClicked.findIndex(value => value === false)
      if (from === 'exit-button' && (currentStep === 0 || currentStep === 2)) {
        // exit button can only be clicked in a valid sequence position
        secureExitClicked[currentStep] = true
        return
      }
      if (from === 'logo' && (currentStep === 1 || currentStep === 3)) {
        // logo button can only be clicked in a valid sequence position
        secureExitClicked[currentStep] = true
        return
      }
      // The sequence is invalid, reset
      secureExitClicked[0] = false
      secureExitClicked[1] = false
      secureExitClicked[2] = false
      secureExitClicked[3] = false
    } else {
      if (from === 'exit-button') {
        navigate('/')
      }
    }
  }

  return (
    <Layout hideHeader hideFooter>
      <Formik<{ qr: string }>
        initialValues={{
          qr: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          const now = new Date().getTime()
          if (values.qr) {
            console.log(values.qr, lastQrSent.value, differenceInSeconds(now, lastQrSent.time))
            if (
              JSON.stringify(values.qr) === JSON.stringify(lastQrSent.value) &&
              differenceInSeconds(now, lastQrSent.time) < 5
            ) {
              return
            }
            try {
              const hash = JSON.parse(values.qr)
              if (hash.content && hash.iv)
                await mutation
                  .mutateAsync(
                    {
                      hash: JSON.parse(values.qr),
                    },
                    {
                      onSuccess: () => {
                        setLastQrSent({
                          value: values.qr,
                          time: now,
                        })
                      },
                    },
                  )
                  .catch(e => e)
            } catch (err) {
              setQrReaderError("Ce QR Code n'est pas valide")
            }
            resetForm({
              values: { qr: '' },
            })
          }
        }}
      >
        {props => {
          return (
            <Hero size={'fullheight'} color={clockingResultColor}>
              <Hero.Header>
                <Columns centered breakpoint={'mobile'} mx={3} mt={2}>
                  <Columns.Column textAlign={'left'}>
                    <button
                      data-test="logo-exit-button"
                      onClick={() => handleExitScannerPage('logo')}
                    >
                      <img src={logo} alt="Fandi Check In" width={30} />
                    </button>
                  </Columns.Column>
                  <Columns.Column textAlign={'center'}>
                    <label className="label has-text-centered">{currentOrganization?.name}</label>
                  </Columns.Column>
                  <Columns.Column textAlign={'right'}>
                    <StatusTag />
                  </Columns.Column>
                </Columns>
              </Hero.Header>
              <Hero.Body justifyContent="center" className="p-0">
                <Columns
                  centered
                  className="is-mobile"
                  style={{ flexBasis: 'min(70vw, 70vh)' }}
                  vCentered
                >
                  <Columns.Column textSize={4} textWeight="bold">
                    {qrReaderError && (
                      <CountBackAndHide cb={() => setQrReaderError(undefined)}>
                        <Beep />
                        <Message color={clockingResultColor} mb={3} textSize={5}>
                          <Message.Body py={3}>
                            {qrReaderError} (<CountBack />)
                          </Message.Body>
                        </Message>
                      </CountBackAndHide>
                    )}
                    {mutation.isSuccess && (
                      <CountBackAndHide cb={() => mutation.reset()}>
                        <Beep />
                        <Message color={clockingResultColor} mb={3}>
                          <Message.Body py={3} textSize={5}>
                            {mutation.data.data.mission ? (
                              <>
                                Pointage enregistré à <br /> {formatTime(mutation.data.data.date)}
                              </>
                            ) : (
                              'Aucune mission en cours'
                            )}
                            &nbsp;(
                            <CountBack />)
                          </Message.Body>
                        </Message>
                      </CountBackAndHide>
                    )}
                    {mutation.isError && (
                      <CountBackAndHide cb={() => mutation.reset()}>
                        <Beep />
                        <Message color={clockingResultColor} mb={3}>
                          <Message.Body py={3} textSize={5}>
                            Pas autorisé (<CountBack />)
                          </Message.Body>
                        </Message>
                      </CountBackAndHide>
                    )}
                    {!qrReaderError && !mutation.isSuccess && !mutation.isError && (
                      <Message color={clockingResultColor} mb={3}>
                        <Message.Body textAlign={'center'} py={3} textSize={5}>
                          Scannez votre QR
                        </Message.Body>
                      </Message>
                    )}
                    {scanner === 'laser' ? (
                      <LaserScannerForm formikProps={props} />
                    ) : (
                      <QRScannerForm
                        camera={camera}
                        setCamera={setCamera}
                        mode={mode}
                        setMode={setMode}
                        setQrReaderError={setQrReaderError}
                        formikProps={props}
                      />
                    )}
                  </Columns.Column>
                </Columns>
              </Hero.Body>
              <Hero.Footer>
                <Level mx={5} mb={3} breakpoint="mobile">
                  <Level.Side>
                    <Element>
                      {isSystemUser ? (
                        <Element textSize={7} textColor="grey">
                          Mode kiosque
                        </Element>
                      ) : (
                        <Button
                          size="small"
                          onClick={() => navigate('/kiosk')}
                          outlined
                          textColor="grey"
                          pull="right"
                          backgroundColor="white"
                        >
                          Créer un kiosque
                        </Button>
                      )}
                    </Element>
                  </Level.Side>
                  <Level.Side>
                    <Button
                      size="small"
                      onClick={() => handleExitScannerPage('exit-button')}
                      color={'ghost'}
                      data-test="exit-qr-scanner"
                      textColor="grey"
                      textWeight="bold"
                      style={{ textDecoration: 'none' }}
                    >
                      x
                    </Button>
                  </Level.Side>
                </Level>
              </Hero.Footer>
            </Hero>
          )
        }}
      </Formik>
    </Layout>
  )
}

export default QrScannerPage

export const LaserScannerForm = ({
  formikProps,
}: {
  formikProps: FormikProps<{
    qr: string
  }>
}): ReactNode => {
  const inputRef = useRef('')
  const { setFieldValue, submitForm } = formikProps

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (inputRef.current === '') return
        setFieldValue('qr', inputRef.current)
        submitForm()
        inputRef.current = ''
      } else {
        if (event.key.length !== 1) return
        inputRef.current = inputRef.current + event.key // Update input as keys are pressed
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return <></>
}

export const QRScannerForm = ({
  mode,
  setMode,
  setQrReaderError,
  camera,
  setCamera,
  formikProps,
}: {
  mode: 'kiosk' | 'mobile' | undefined
  setMode: React.Dispatch<React.SetStateAction<'kiosk' | 'mobile' | undefined>>
  setQrReaderError: React.Dispatch<React.SetStateAction<string | undefined>>
  camera: 'user' | 'environment' | undefined
  setCamera: React.Dispatch<React.SetStateAction<'user' | 'environment' | undefined>>
  formikProps: FormikProps<{
    qr: string
  }>
}): ReactNode => {
  return (
    <Form>
      <>
        {
          <>
            <Element style={{ transform: mode === 'kiosk' ? 'scaleX(-1)' : '' }}>
              <Field
                name="qr"
                component={QrScannerField}
                setQrReaderError={setQrReaderError}
                required
                facingMode={camera}
                data-test="camera-scanner-input-field"
              />
            </Element>
            <Element px={2} mt={5}>
              <Level>
                <Level.Side>
                  <Element>
                    <Tabs size={'small'} type="toggle-rounded" color="primary">
                      <Tabs.Tab
                        active={mode === 'kiosk'}
                        onClick={() => setMode('kiosk')}
                        backgroundColor={mode !== 'kiosk' ? 'light' : undefined}
                      >
                        {mode === 'kiosk' && (
                          <Icon>
                            <CheckCircleIcon color="success" />
                          </Icon>
                        )}
                        Kiosque
                      </Tabs.Tab>
                      <Tabs.Tab
                        active={mode === 'mobile'}
                        onClick={() => setMode('mobile')}
                        backgroundColor={mode !== 'mobile' ? 'light' : undefined}
                      >
                        {mode === 'mobile' && (
                          <Icon>
                            <CheckCircleIcon />
                          </Icon>
                        )}
                        Mobile
                      </Tabs.Tab>
                    </Tabs>
                  </Element>
                </Level.Side>
                <Level.Side>
                  <Tabs size="small" type="toggle-rounded">
                    <Tabs.Tab
                      backgroundColor="white"
                      onClick={() => setCamera(camera === 'user' ? 'environment' : 'user')}
                    >
                      <Icon color="primary">
                        <RefreshIcon />
                      </Icon>
                    </Tabs.Tab>
                  </Tabs>
                </Level.Side>
              </Level>
            </Element>
          </>
        }
      </>
      <input
        type="text"
        name="qr"
        onChange={formikProps.handleChange}
        value={formikProps.values.qr}
        style={{
          border: 0,
          boxShadow: 'none',
          color: 'transparent',
          backgroundColor: 'transparent',
        }}
      />
      <AutoSubmit values={formikProps.values} submitForm={formikProps.submitForm} />
    </Form>
  )
}
