import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { InterimAgency } from '../../api/organizations'
import AgencySubscriptionForm from '../organizations/agency-subscription-form'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import { Box } from 'react-bulma-components'

interface AppSubscriptionOptionsProps {
  interimAgency?: InterimAgency
}

const AppSubscriptionOptions: React.FC = () => {
  const { interimAgency } = useOutletContext<AppSubscriptionOptionsProps>()

  return (
    <>
      <SidebarContentTitle title="Options d'abonnement" />
      <Box>
        <AgencySubscriptionForm interimAgency={interimAgency} />
      </Box>
    </>
  )
}

export default AppSubscriptionOptions
