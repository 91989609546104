import React from 'react'
import InputField from '../../form/fields/input'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import { IsoDate } from '../../../hooks/use-calendar'

interface DateFilterFieldProps {
  name?: string
  label?: string
  min?: IsoDate
  max?: IsoDate
}

const DateFilterField: React.FC<DateFilterFieldProps> = ({ name, label, min, max }) => {
  return (
    <FieldColumn>
      <Field
        label={label}
        name={name || 'date'}
        type="date"
        component={InputField}
        min={min}
        max={max}
      />
    </FieldColumn>
  )
}

export default DateFilterField
