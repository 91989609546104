import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './employer-settings-wrapper'
import { Box } from 'react-bulma-components'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import {
  useCreateMutationKey,
  useOneClockingRulesQuery,
  useUpdateMutationKey,
} from '../../queries/clocking-rules'
import ClockingRulesExplanation from '../../components/clocking-rules/clocking-rules-explanation'
import useUrlId from '../../hooks/use-url-id'
import ClockingsRulesForm from '../../components/clocking-rules/clocking-rules-form'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import { useMutationState } from '../../queries'
import FormWrapper from '../../components/form/form-wrapper'

/**
 * Composant pour afficher et éditer les détails d'une règle de pointage
 */
const ClockingRuleDetails: React.FC = () => {
  //const { employer } = useOutletContext<OutletContext>()
  const { id, isNew } = useUrlId()

  const { data: clockingRule } = useOneClockingRulesQuery(id, {
    enabled: Boolean(id),
  })

  const mutationState = useMutationState(isNew ? useCreateMutationKey() : useUpdateMutationKey())

  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    //@ts-ignore
    mutationState,
    !isNew,
  )

  return (
    <>
      <SidebarContentTitle
        title={
          isNew
            ? 'Nouvelle règle de pointage'
            : `Détails de la règle de pointage ${clockingRule?.name}`
        }
        help={<ClockingRulesExplanation textColor={'white'} />}
      />
      <FormWrapper {...formWrapperProps}>
        <Box>
          <ClockingsRulesForm clockingRules={clockingRule} />
        </Box>
      </FormWrapper>
    </>
  )
}

export default ClockingRuleDetails

const formWrapperBaseProps = {
  loadingProps: { body: 'Enregistrement des Règles de Pointage...' },
  successProps: {
    title: 'Règles de Pointages créées',
    body: {
      description: 'Les Règles de Pointage ont été créées',
      actions: [
        {
          label: 'Nouvelles Règles de Pointage',
        },
        {
          label: 'Retourner à la liste',
          to: '/settings/clocking-rules',
        },
      ],
    },
  },
}
