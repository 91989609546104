import {
  EmployerSchema,
  InterimAgencySchema,
  Organization as OrganizationSchema,
} from '../../../backend/src/services/resources/organizations/organization.model'
import api, { ApiResponse } from '.'

export interface Organization extends Omit<OrganizationSchema, '_id'> {
  _id: string
}
export interface InterimAgency extends InterimAgencySchema {
  _id: string
}
export interface Employer extends EmployerSchema {
  _id: string
}
export enum OrganizationTypeEnum {
  interimAgency = 'interimAgency',
  employer = 'employer',
}

interface FetchOrganizationsParams {
  type?: string
  [key: string]: any
}

const POPULATE = ['associations.interimAgency', 'associations.invitedBy']

export const fetch = async (
  params?: FetchOrganizationsParams,
): Promise<ApiResponse<Organization[]>> =>
  await api({
    endpoint: '/organizations',
    method: 'get',
    params: {
      sort: '-createdAt',
      populate: POPULATE,
      ...(params || {}),
    },
  })

interface GetOrganizationParams {
  id: string
  type: OrganizationTypeEnum
}

export const get = async (params: GetOrganizationParams): Promise<ApiResponse<Organization>> => {
  const { id, ...rest } = params
  return await api({
    endpoint: `/organizations/${id}`,
    method: 'get',
    params: {
      populate: ['associations.interimAgency', 'associations.invitedBy'],
      ...rest,
    },
  })
}

export interface OrganizationCreationPayload {
  name: string
  type: OrganizationTypeEnum
}

export const create = async (
  params: OrganizationCreationPayload,
): Promise<ApiResponse<Organization>> =>
  await api({
    endpoint: '/organizations',
    method: 'post',
    params,
  })

export interface OrganizationUpdatePayload extends OrganizationCreationPayload {
  id: string
}

export const update = async (
  params: OrganizationUpdatePayload,
): Promise<ApiResponse<Organization>> => {
  const { id, ...payload } = params
  return await api({
    endpoint: `/organizations/${id}`,
    method: 'put',
    params: payload,
  })
}

export interface OrganizationCreateAssociationPayload {
  employerId: string
  interimAgencyId: string
}

export const createAssociation = async (
  params: OrganizationCreateAssociationPayload,
): Promise<ApiResponse<Organization>> =>
  await api({
    endpoint: `/organizations/${params.employerId}/associations`,
    method: 'post',
    params,
  })

export interface OrganizationUpdateAssociationPayload {
  employerId: string
  associationId: string
  status: 'accepted' | 'rejected'
}

export const updateAssociation = async (
  params: OrganizationUpdateAssociationPayload,
): Promise<ApiResponse<Organization>> => {
  const { employerId, associationId, ...payload } = params
  console.log('updateAssociation', employerId, associationId, payload)
  return await api({
    endpoint: `/organizations/${employerId}/associations/${associationId}`,
    method: 'put',
    params: payload,
  })
}
