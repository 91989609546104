import api, { ApiResponse } from '.'
import { ClockingRuleSchema } from '../../../backend/src/services/resources/clocking-rules/clocking-rule.model'
import {
  ClockingRulesControllerCreationPayload,
  ClockingRulesControllerUpdatePayload,
} from '../../../backend/src/services/resources/clocking-rules/clocking-rules.controller.service.types'
export interface ClockingRules extends ClockingRuleSchema {
  _id: string
}
export type ClockingRulesCreationPayload = ClockingRulesControllerCreationPayload
export type ClockingRulesUpdatePayload = ClockingRulesControllerUpdatePayload

export const get = async (id: ClockingRules['_id']): Promise<ApiResponse<ClockingRuleSchema>> => {
  return await api({
    endpoint: `/clocking-rules/${id}`,
    method: 'get',
    params: {
      sort: '-createdAt',
    },
  })
}

export const fetch = async (/* params: FetchClockingsParams */): Promise<
  ApiResponse<ClockingRuleSchema>
> => {
  return await api({
    endpoint: '/clocking-rules',
    method: 'get',
    params: {
      sort: 'name',
      //'date>': subDays(startOfDay(new Date()), 7),
      //populate: POPULATE,
      //limit: params?.limit,
    },
  })
}

export const create = async (
  params: ClockingRulesCreationPayload,
): Promise<ApiResponse<ClockingRules>> => {
  return await api({
    endpoint: '/clocking-rules',
    method: 'post',
    params,
  })
}

export const update = async (
  params: ClockingRulesUpdatePayload,
): Promise<ApiResponse<ClockingRules>> => {
  return await api({
    endpoint: `/clocking-rules/${params._id}`,
    method: 'put',
    params,
  })
}
