import React, { ReactNode, useEffect, useState } from 'react'
import Layout from '../../../components/layout/layout'
import {
  Button,
  Container,
  Element,
  Heading,
  Message,
  Modal,
  Section,
} from 'react-bulma-components'
import { EmployerTermsOfUse } from './employer'
import { InterimAgencyTermsOfUse } from './interim-agency'
import { WorkerTermsOfUse } from './worker'
import AcceptNewConditionForm from '../../../components/legal-conditions/accept-new-conditions-form'
import useUserLegalConditionsVersionsStatus from '../../../hooks/use-user-legal-conditions-status'

export const TermsOfUsePage: React.FC = () => {
  const legalConditionsStatus = useUserLegalConditionsVersionsStatus()
  const termsOfUseAreOutdated = legalConditionsStatus.termsOfUse === 'outdated'
  const [showTermsOfUseAreOutdatedBanner, setShowTermsOfUseAreOutdatedBanner] = useState(false)

  useEffect(() => {
    if (termsOfUseAreOutdated === true) {
      setShowTermsOfUseAreOutdatedBanner(true)
    }
  }, [termsOfUseAreOutdated])

  return (
    <Layout hideHeader={termsOfUseAreOutdated}>
      <Section size="medium">
        <Container max={true} breakpoint={'desktop'}>
          {showTermsOfUseAreOutdatedBanner && (
            <Modal show={true}>
              <Modal.Content>
                <Message color={'warning'}>
                  <Message.Header>
                    Les Conditions Générales d'Utilisation de TeamTim ont été mises à jour.
                  </Message.Header>
                  <Message.Body p={5}>
                    <Element renderAs="p">
                      Merci de les consulter et de les accepter pour continuer à utiliser TeamTim.
                      <Element textAlign={'center'}>
                        <Button
                          data-test="continue"
                          type="button"
                          color={'primary'}
                          mt={5}
                          onClick={() => setShowTermsOfUseAreOutdatedBanner(false)}
                        >
                          Continuer
                        </Button>
                      </Element>
                    </Element>
                  </Message.Body>
                </Message>
              </Modal.Content>
            </Modal>
          )}
          <Heading textColor="primary" mb={6}>
            Conditions Générales d'Utilisation de TeamTim
          </Heading>
          <Element mb={6}>
            <EmployerTermsOfUse />
          </Element>
          <Element mb={6}>
            <InterimAgencyTermsOfUse />
          </Element>
          <Element mb={6}>
            <WorkerTermsOfUse />
          </Element>
          <Element id={'accept'}>
            {termsOfUseAreOutdated && <AcceptNewConditionForm type="termsOfUse" />}
          </Element>
        </Container>
      </Section>
    </Layout>
  )
}

export const TermsOfUseTitle2: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Heading renderAs="h2" size={4}>
    {children}
  </Heading>
)
export const TermsOfUseTitle3: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Heading renderAs="h3" size={5}>
    {children}
  </Heading>
)
export const TermsOfUseTitle4: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Heading renderAs="h4" size={6} mt={3} mb={3}>
    {children}
  </Heading>
)
