import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Icon, Element } from 'react-bulma-components'
import NotificationBadge from '../../notifications/badge'

interface SidebarMenuItemProps {
  path: string
  label: string
  icon: React.ComponentType
  badgeCount?: number
  isActive: boolean
  basePath: string
}

/**
 * Menu item component for the sidebar
 * Displays an icon, label and optional badge
 */
const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  path,
  label,
  icon: IconComponent,
  badgeCount,
  isActive,
  basePath,
}) => {
  const fullPath = `${basePath}/${path}`

  return (
    <Menu.List.Item active={isActive} renderAs={Link} to={fullPath}>
      <Element
        renderAs="span"
        className="is-flex is-align-items-center is-justify-content-space-between w-100"
      >
        <Element className="is-flex is-align-items-center">
          <Icon size="small" className="mr-2">
            <IconComponent />
          </Icon>
          <Element renderAs="span">{label}</Element>
        </Element>
        <NotificationBadge count={badgeCount || 0} />
      </Element>
    </Menu.List.Item>
  )
}

export default SidebarMenuItem
