import React from 'react'
import { Employer } from '../../api/organizations'
import SidebarLayout from '../../components/layout/sidebar-layout'
import {
  EmployerIcon,
  InterimAgencyIcon,
  JobTitleIcon,
  TerminalIcon,
  WorkerIcon,
  WorkPeriodEventIcon,
} from '../../components/icons'
import useStore from '../../store'
import { ClockIcon, UserGroupIcon } from '@heroicons/react/outline'
import { CurrencyEuroIcon } from '@heroicons/react/outline'
import { usePendingEmployerInvitationsCount } from '../../hooks/use-pending-employer-invitations-count'
import { useOrganizationQuery } from '../../queries/organizations'
import { isAuthorizedForOption } from '../../components/protected/organization-option-protected'
import { Navigate, useLocation } from 'react-router-dom'

export interface OutletContext {
  employer: Employer
}

const EmployerSettingsWrapper: React.FC = () => {
  const location = useLocation()
  const currentEmployer = useStore(state => state.session.currentOrganization as Employer)
  const { data: employer } = useOrganizationQuery<Employer>(currentEmployer._id, 'employer')
  const pendingEmployerInvitationsCount = usePendingEmployerInvitationsCount()

  const menu = {
    missionsAndWorkPeriods: {
      title: 'Missions & Journées de Travail',
      sections: [
        {
          path: 'work-period-events',
          label: 'Évènements',
          icon: WorkPeriodEventIcon,
        },
        {
          path: 'job-titles',
          label: 'Intitulés de Poste',
          icon: JobTitleIcon,
        },
        {
          path: 'clocking-rules',
          label: 'Règles de Pointage',
          icon: ClockIcon,
        },
      ],
    },
    finances: {
      title: 'Finances',
      sections: [
        {
          path: 'employer-rules',
          label: 'Règles financières',
          icon: CurrencyEuroIcon,
        },
        {
          path: 'labor-costs-coefficients',
          label: 'Coefficients coût travail temporaires',
          icon: () => <>%</>,
        },
      ],
    },
    collaboration: {
      title: 'Collaboration',
      sections: [
        {
          path: 'partners-agencies',
          label: 'Agences Partenaires',
          icon: InterimAgencyIcon,
          badgeCount: pendingEmployerInvitationsCount,
        },
        {
          path: 'members',
          label: 'Gestionnaires de mon entreprise',
          icon: UserGroupIcon,
        },
      ],
    },
    settings: {
      title: 'Paramètres',
      sections: [
        {
          path: 'info',
          label: "Informations de l'Entreprise",
          icon: EmployerIcon,
        },
        {
          path: 'terminals',
          label: 'Bornes de Pointage',
          icon: TerminalIcon,
        },
      ],
    },
  }

  if (isAuthorizedForOption([{ organizationType: 'employer', option: 'biPart' }])) {
    menu.missionsAndWorkPeriods.sections.push({
      path: 'workers',
      label: 'Salariés',
      icon: WorkerIcon,
    })
  }

  if (!employer) {
    return <></>
  }

  if (location.pathname === '/settings') {
    return <Navigate to="/settings/info" replace />
  }

  return (
    <SidebarLayout<OutletContext> basePath={`/settings`} menu={menu} outletContext={{ employer }} />
  )
}

export default EmployerSettingsWrapper
