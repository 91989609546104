import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './employer-settings-wrapper'
import { Box } from 'react-bulma-components'
import { useUsersQuery } from '../../queries/users'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import UsersTable from '../../components/users/tables/users-table'
import EmployerMemberExplanation from '../../components/organizations/employer-member-explanation'
import UsersFilters, { UsersFiltersValues } from '../../components/users/filters'
import InviteUserButton from '../../components/users/buttons/invite-user'
import { getWorkerRoleOrAssociationStatus } from '../../utils/users'

/**
 * Component for managing employer team members
 * Displays a list of members with their roles and permissions
 */
const MembersSettings: React.FC = () => {
  const { employer } = useOutletContext<OutletContext>()

  const [filters, setFilters] = React.useState<UsersFiltersValues>({
    // TODO: refactor using filters in store and in http requests
    status: ['accepted', 'pending'],
  })

  // Query to fetch employer members
  const query = useUsersQuery({
    'roles.kind': 'employerMember',
  })

  const users = (query.data || []).filter(user => {
    // TODO: refactor using filters in store and in http requests
    const roleOrAssociationStatus = getWorkerRoleOrAssociationStatus(
      user,
      'employerMember',
      employer,
    )
    return (
      !filters.status ||
      !filters.status[0] ||
      !roleOrAssociationStatus ||
      filters.status.includes(roleOrAssociationStatus)
    )
  })

  return (
    <>
      <SidebarContentTitle
        title="Gestionnaires de mon entreprise"
        ActionButton={<InviteUserButton userRoleKind={'employerMember'} />}
        help={<EmployerMemberExplanation textColor={'white'} />}
      />

      <Box>
        <UsersFilters
          onChange={setFilters}
          initialValues={{
            status: ['accepted', 'pending'],
          }}
        />
        <UsersTable users={users} roleKind="employerMember" />
      </Box>
    </>
  )
}

export default MembersSettings
