import React from 'react'
import { RequestButton } from '../../request-components/request-components'
import { useCreateMutation } from '../../../queries/users'
import { CreateUserParams } from '../../../../../backend/src/services/resources/users/users.controller.service.types'
import { UserRoleKind } from '../../../api/users'
import { Organization } from '../../../api/organizations'
import { Icon } from 'react-bulma-components'
import { PaperAirplaneIcon } from '@heroicons/react/outline'

interface ResendUserInvitationButtonProps {
  userEmail: string
  roleKind: UserRoleKind
  organization: Organization
  color?: string
}

const ResendUserInvitationButton: React.FC<ResendUserInvitationButtonProps> = ({
  userEmail,
  roleKind,
  organization,
  color,
}) => {
  const createUserMutation = useCreateMutation()

  const mutate = () => {
    const mutationPayload: CreateUserParams = {
      email: userEmail,
      appAccess: 'full',
      role: {
        kind: roleKind,
      },
    }
    if (roleKind === 'employerMember' || roleKind === 'interimAgencyMember') {
      mutationPayload.role.organizationRole = 'admin'
      mutationPayload.role.organization = organization._id
    } else if (roleKind === 'worker') {
      mutationPayload.association = organization._id
    }
    createUserMutation.mutate(mutationPayload)
  }

  return (
    <RequestButton
      size={'small'}
      onClick={mutate}
      disabled={createUserMutation.isSuccess}
      mr={'2'}
      mutation={createUserMutation}
      color={color}
    >
      <span className="is-flex is-align-items-center">
        <Icon mr={1}>
          <PaperAirplaneIcon />
        </Icon>
        <span>Ré-envoyer l'invitation</span>
      </span>
    </RequestButton>
  )
}

export default ResendUserInvitationButton
