import React from 'react'
import { OrganizationTypeEnum } from '../../../api/organizations'
import MultiSelectField from '../../form/fields/multi-select'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import { buildListAsInputOptions } from '../../../utils/forms'
import { useAssociatedOrganizationsQuery } from '../../../queries/organizations'
import SelectField from '../../form/fields/select'

interface OrganizationFilterFieldProps {
  disabledQueries?: boolean
  queriesStaleTime?: number
  type: OrganizationTypeEnum
  singleSelect?: boolean
}

const OrganizationFilterField: React.FC<OrganizationFilterFieldProps> = ({
  type,
  disabledQueries,
  queriesStaleTime,
  singleSelect,
}) => {
  const organizationsQuery = useAssociatedOrganizationsQuery({
    enabled: !disabledQueries,
    staleTime: queriesStaleTime,
  })

  return (
    <FieldColumn>
      <Field
        label={type === OrganizationTypeEnum.employer ? 'Entreprises Utilisatrices' : 'Agence'}
        name={type === OrganizationTypeEnum.employer ? 'employer' : 'interimAgency'}
        component={singleSelect ? SelectField : MultiSelectField}
        options={buildListAsInputOptions(organizationsQuery.data || [])}
      />
    </FieldColumn>
  )
}

export default OrganizationFilterField
