import { FieldProps } from 'formik'
import React from 'react'
import { Form } from 'react-bulma-components'
import FormField, { FormFieldProps } from './form-field'

interface SelectFieldOption {
  value: string
  label: string
  required?: boolean
}

interface SelectFieldProps extends FormFieldProps, FieldProps {
  options: SelectFieldOption[]
  selectDefaultChooseOptionLabel?: string
}

const SelectField: React.FunctionComponent<SelectFieldProps> = props => {
  const { field, form, options, selectDefaultChooseOptionLabel, ...rest } = props
  const { required } = rest
  return (
    <FormField
      label={props.label}
      error={form.errors[field.name] as string}
      help={props.help}
      required={required}
    >
      <Form.Select
        {...field}
        {...rest}
        value={field.value}
        className={field.value ? 'is-not-empty' : 'is-empty'}
      >
        <option style={{ display: 'none' }} value={undefined}>
          {selectDefaultChooseOptionLabel ?? 'Choisir une option'}
        </option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </FormField>
  )
}

export default SelectField
