import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './interim-agency-settings-wrapper'
import { useUsersQuery } from '../../queries/users'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import UsersFilters, { UsersFiltersValues } from '../../components/users/filters'
import { Box, Tabs } from 'react-bulma-components'
import WorkerLabel from '../../components/texts/worker-label'
import { User } from '../../api/users'
import UsersTable from '../../components/users/tables/users-table'
import { getWorkerRoleOrAssociationStatus } from '../../utils/users'
import CreateOfflineWorkerButton from '../../components/users/buttons/create-offline-worker'
import InviteUserButton from '../../components/users/buttons/invite-user'
import InterimAgencyWorkerExplanation from '../../components/users/interim-agency-worker-explanation'

/**
 * Component for managing employer workers
 */
const WorkersSettings: React.FC = () => {
  const { interimAgency } = useOutletContext<OutletContext>()
  const [filters, setFilters] = React.useState<UsersFiltersValues>({
    // TODO: refactor using filters in store and in http requests
    status: ['accepted', 'pending'],
  })

  const [currentAppAccessTab, setCurrentAppAccessTab] = React.useState<User['appAccess']>('full')

  const query = useUsersQuery({
    'roles.kind': 'worker',
    appAccess: currentAppAccessTab,
  })

  const tabs: { label: React.ReactNode; value: User['appAccess'] }[] = [
    {
      label: (
        <>
          <WorkerLabel /> Connecté
        </>
      ),
      value: 'full',
    },
    {
      label: (
        <>
          <WorkerLabel /> Offline
        </>
      ),
      value: 'none',
    },
  ]

  const users = (query.data || []).filter(user => {
    // TODO: refactor using filters in store and in http requests
    const roleOrAssociationStatus = getWorkerRoleOrAssociationStatus(user, 'worker', interimAgency)
    return (
      !filters.status ||
      !filters.status[0] ||
      !roleOrAssociationStatus ||
      filters.status.includes(roleOrAssociationStatus)
    )
  })

  return (
    <>
      <SidebarContentTitle
        title="Intérimaires"
        help={<InterimAgencyWorkerExplanation textColor="white" />}
        ActionButton={
          currentAppAccessTab === 'none' ? (
            <CreateOfflineWorkerButton />
          ) : (
            <InviteUserButton userRoleKind={'worker'} />
          )
        }
      />
      <Box>
        <UsersFilters
          onChange={setFilters}
          initialValues={{
            status: ['accepted', 'pending'],
          }}
        />

        <Tabs>
          {tabs.map(tab => (
            <Tabs.Tab
              key={tab.value}
              active={currentAppAccessTab === tab.value}
              onClick={() => setCurrentAppAccessTab(tab.value)}
              data-test={`workers-list-tab-${tab.value}`}
            >
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs>
        <UsersTable
          users={users}
          roleKind={currentAppAccessTab === 'none' ? 'offlineWorker' : 'worker'}
        />
      </Box>
    </>
  )
}

export default WorkersSettings
