import React from 'react'
import { Element } from 'react-bulma-components'

interface LaborCostsCoefficientsExplanationProps {
  textColor?: string
}

const LaborCostsCoefficientsExplanation: React.FC<LaborCostsCoefficientsExplanationProps> = ({
  textColor,
}) => {
  return (
    <>
      <Element mb={2}>
        Les coefficients de coût de travail temporaire sont des multiplicateurs appliqués au taux
        horaire de base (SMIC) pour déterminer le coût final d'une journée de travail. Ces
        coefficients sont définis pour chaque intitulé de poste et par association entre une agence
        d'intérim et un employeur.
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Coefficient Délégation
        </Element>
        <Element>
          Appliqué lorsque l'agence d'intérim s'occupe du recrutement complet : recherche de
          candidats, sélection et gestion administrative. Ce coefficient est généralement plus élevé
          car il inclut la prestation de sourcing.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Coefficient Gestion
        </Element>
        <Element>
          Appliqué lorsque l'entreprise propose directement le candidat à l'agence d'intérim, qui ne
          s'occupe alors que de la gestion administrative. Ce coefficient est plus faible car il
          n'inclut pas la partie recrutement.
        </Element>
      </Element>

      <Element>
        <Element renderAs="i" textSize={7}>
          Chaque association employeur-agence définit ses propres coefficients par intitulé de
          poste. Un même intitulé de poste peut avoir des coefficients différents selon l'agence
          partenaire.
        </Element>
      </Element>
    </>
  )
}

export default LaborCostsCoefficientsExplanation
