import React from 'react'
import { Box, Icon, Message } from 'react-bulma-components'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import { useJobTitlesQuery } from '../../queries/job-titles'
import LaborCostsCoefficientsTable from '../../components/organizations/labor-costs-coefficients-table'
import { useOrganizationsQuery } from '../../queries/organizations'
import { InterimAgency } from '../../api/organizations'
import LaborCostsCoefficientsFilters from '../../components/organizations/labor-costs-coefficients-filters'
import { FiltersResourcesEnum } from '../../store/filters'
import useFilters from '../../hooks/filters'
import { getJobTitleLaborCoefficientByInterimAgency } from '../../utils/job-titles'
import { WarningIcon } from '../../components/icons'
import Modal from '../../components/modal'
import JobTitleLaborCoefficientForm from '../../components/job-titles/job-title-labor-coefficient-form'
import { JobTitle } from '../../api/job-titles'
import LaborCostsCoefficientsExplanation from '../../components/organizations/labor-costs-coefficients-explanation'

/**
 * Composant pour gérer les coefficients de coûts de travail temporaire
 */
const LaborCostsCoefficients: React.FC = () => {
  //const { employer: _employer } = useOutletContext<OutletContext>()
  const { filters } = useFilters(FiltersResourcesEnum.laborCostsCoefficients)
  const { data: interimAgencies } = useOrganizationsQuery<InterimAgency>({
    type: 'interimAgency',
  })
  const { data: allJobTitles } = useJobTitlesQuery({
    populate: ['laborCostCoefficients.intermAgency'],
    sort: 'name',
  })

  const [currentJobTitle, setCurrentJobTitle] = React.useState<JobTitle | null>(null)

  const interimAgency = interimAgencies?.find(agency => agency._id === filters.interimAgency)
  const jobTitles = !filters.jobTitle?.[0]
    ? allJobTitles
    : allJobTitles?.filter(jobTitle => filters.jobTitle?.includes(jobTitle._id))

  const hasMissingCoefficients =
    interimAgency &&
    jobTitles?.some(jobTitle => {
      const jobTitleCoefficients = getJobTitleLaborCoefficientByInterimAgency(
        jobTitle,
        interimAgency,
      )
      return !jobTitleCoefficients?.managementValue || !jobTitleCoefficients?.delegationValue
    })

  console.log(currentJobTitle, interimAgency)

  return (
    <>
      <SidebarContentTitle
        title="Coefficients coût travail temporaires"
        help={<LaborCostsCoefficientsExplanation textColor={'white'} />}
      />
      <Box>
        <LaborCostsCoefficientsFilters />
        {hasMissingCoefficients && (
          <Message color="warning" className="mb-4">
            <Message.Body>
              <Icon.Text>
                <Icon>
                  <WarningIcon />
                </Icon>
                Attention : certains postes n'ont pas de coefficients définis
              </Icon.Text>
            </Message.Body>
          </Message>
        )}
        {interimAgency ? (
          <LaborCostsCoefficientsTable
            interimAgency={interimAgency}
            jobTitles={jobTitles || []}
            onEdit={jobTitleId => {
              setCurrentJobTitle(jobTitles?.find(jobTitle => jobTitle._id === jobTitleId) || null)
            }}
          />
        ) : (
          <Message color="info" className="mb-4">
            <Message.Body>
              <Icon.Text>
                <Icon>
                  <WarningIcon />
                </Icon>
                Veuillez sélectionner une agence d'intérim pour visualiser les coefficients
              </Icon.Text>
            </Message.Body>
          </Message>
        )}
        <Modal
          actions={{
            isDisplayed: Boolean(interimAgency && currentJobTitle),
            setIsDisplayed: () => setCurrentJobTitle(null),
          }}
          title="Modifier les coefficients"
        >
          {interimAgency && currentJobTitle && (
            <JobTitleLaborCoefficientForm
              interimAgency={interimAgency}
              jobTitle={currentJobTitle}
            />
          )}
        </Modal>
      </Box>
    </>
  )
}

export default LaborCostsCoefficients
