import React from 'react'
import { Columns, Container, Heading, Message, Section } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import LoginForm from '../../components/session/login-form'
import { useLocation } from 'react-router-dom'

const Login: React.FC = () => {
  const { state } = useLocation()
  const message = state?.message

  return (
    <Layout>
      <Section size="medium">
        <Container>
          <Columns centered>
            <Columns.Column size={4}>
              {message && (
                <Message color={message.color}>
                  {message.header && <Message.Header>{message.header}</Message.Header>}
                  <Message.Body>{message.body}</Message.Body>
                </Message>
              )}
              <Heading>Login</Heading>
              <LoginForm />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}

export default Login
