const config = {
  mobileApps: {
    apple: {
      store: {
        url: 'https://apps.apple.com/be/app/teamtim/id6449463217',
      },
    },
    android: {
      store: {
        url: 'https://play.google.com/store/apps/details?id=fr.teamtim.app',
      },
    },
  },
  currentPrivacyPolicyVersion: '1.0.1',
  currentTermsOfUseVersion: '1.0.1',
  notifications: {
    admins: ['dev@codefathers.be', 'support@teamtim.fr'],
    sender: 'notifications@teamtim.fr',
  },
  clockings: {
    workPeriodMatchRanges: {
      //In hours
      start: 2,
      end: 4,
    },
  },
  passwordValidation: {
    // Password Validation Rules
    // The below element pattern(regular expression) will be applied to password field.
    // (?=.*\d) -> at least one numeric value(0-9).
    // (?=.*[a-z]) -> at least one lowercase letter(a - z).
    // (?=.*[A-Z]) -> at least one uppercase letter(A - Z).
    //(?=.*[^a-zA-Z0-9]) -> at least one character which is not of the three above !(a-z) || !(A-Z) || !(d)
    // {8,} -> the total length should be greater than or equal to 8.
    rule: new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/),
    message:
      'Le mot de passe doit avoir une longueur minimum de 8 caractères comprenant 1 lettre minuscule, 1 lettre majuscule, 1 chiffre et 1 caractère spécial.',
  },
  workersRequests: {
    recourseCases: {
      reasons: {
        remplacement_d_un_salarie: "Remplacement d'un Salarié",
        accroissement_temporaire_d_activite: "Accroissement temporaire d'activité",
        emploi_saisonnier_ou_d_usage_constant: "Emploi saisonnier ou d'usage constant",
        remplacement_d_un_non_salarie: "Remplacement d'un non salarié",
        complement_de_formation: 'Complément de formation',
        Personne_en_situation_de_handicap: 'Personne en situation de handicap',
        apprentissage: 'Apprentissage',
      },
    },
  },
}

export default config
