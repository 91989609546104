import {
  AppSubscriptionPeriod as AppSubscriptionPeriodSchema,
  AppSubscriptionPeriodsInterimAgencyTotal as _AppSubscriptionPeriodsInterimAgencyTotal,
  AppSubscriptionPeriodsInterimAgencyPeriod as _AppSubscriptionPeriodsInterimAgencyPeriod,
} from '../../../backend/src/services/resources/app-subscription-periods/app-subscription-period.types'

import { AppSubscriptionPeriodBusinessCreationPayload } from '../../../backend/src/services/resources/app-subscription-periods/app-subscription-periods.business.service.types'

import api, { ApiResponse } from '.'

export interface AppSubscriptionPeriod extends Omit<AppSubscriptionPeriodSchema, '_id'> {
  _id: string
}

/**
 * Récupère une liste des périodes d'abonnement
 * @param params - Paramètres de filtrage optionnels
 */
export const fetch = async (
  params?: Record<string, any>,
): Promise<ApiResponse<AppSubscriptionPeriod[]>> =>
  await api({
    endpoint: '/app-subscription-periods',
    method: 'get',
    params: {
      sort: '-periodEnd',
      populate: ['interimAgency', 'employer'],
      ...(params || {}),
    },
  })

/**
 * Récupère une période d'abonnement spécifique
 * @param id - Identifiant de la période d'abonnement
 */
export const get = async (id: string): Promise<ApiResponse<AppSubscriptionPeriod>> =>
  await api({
    endpoint: `/app-subscription-periods/${id}`,
    method: 'get',
    params: {
      populate: ['interimAgency', 'employer'],
    },
  })

export type AppSubscriptionPeriodsInterimAgencyPeriod = _AppSubscriptionPeriodsInterimAgencyPeriod

/**
 * Paramètres pour le calcul des totaux d'une période
 */
export interface CalculatePeriodTotalsParams {
  interimAgencyId: string
  periodNumber?: number
  periodYear?: number
}

export type AppSubscriptionPeriodsInterimAgencyTotal = _AppSubscriptionPeriodsInterimAgencyTotal

/**
 * Calcule les totaux pour une période donnée
 * @param params - Paramètres de calcul des totaux
 */
export const calculatePeriodTotals = async (
  params: CalculatePeriodTotalsParams,
): Promise<ApiResponse<AppSubscriptionPeriodsInterimAgencyTotal>> => {
  const { interimAgencyId, periodNumber, periodYear } = params
  return await api({
    endpoint: `/app-subscription-periods/interim-agency/${interimAgencyId}/totals`,
    method: 'get',
    params: {
      periodNumber,
      periodYear,
    },
  })
}

/**
 * Récupère toutes les périodes d'abonnement pour une agence d'intérim
 * @param interimAgencyId - ID de l'agence d'intérim
 */
export const getAllPeriods = async (
  interimAgencyId: string,
): Promise<ApiResponse<AppSubscriptionPeriodsInterimAgencyPeriod[]>> =>
  await api({
    endpoint: `/app-subscription-periods/interim-agency/${interimAgencyId}/periods`,
    method: 'get',
  })

export type AppSubscriptionPeriodCreationPayload = AppSubscriptionPeriodBusinessCreationPayload
/**
 * Crée une nouvelle période d'abonnement (réservé aux super admins)
 * @param params - Données de création de la période d'abonnement
 */
export const create = async (
  params: AppSubscriptionPeriodBusinessCreationPayload,
): Promise<ApiResponse<AppSubscriptionPeriod>> =>
  await api({
    endpoint: '/app-subscription-periods',
    method: 'post',
    params,
  })

/**
 * Met à jour une période d'abonnement existante
 * @param id - Identifiant de la période d'abonnement
 * @param params - Données de mise à jour de la période
 */
export const update = async (params: {
  _id: string
  invoiceRef?: string
  comment?: string
}): Promise<ApiResponse<AppSubscriptionPeriod>> =>
  await api({
    endpoint: `/app-subscription-periods/${params._id}`,
    method: 'put',
    params,
  })

/**
 * Interface pour les paramètres de recherche par période
 */
export interface GetByPeriodParams {
  interimAgencyId: string
  employerId: string
  periodYear: number
  periodNumber: number
}

/**
 * Récupère une période d'abonnement par ses paramètres de période
 * @param params - Paramètres de recherche de la période
 */
export const getByPeriod = async (
  params: GetByPeriodParams,
): Promise<ApiResponse<AppSubscriptionPeriod>> =>
  await api({
    endpoint: `/app-subscription-periods/interim-agency/${params.interimAgencyId}/employer/${params.employerId}/year/${params.periodYear}/period/${params.periodNumber}`,
    method: 'get',
  })
