import React from 'react'
import Table from '../table'
import { Column } from 'react-table'
import { JobTitle } from '../../api/job-titles'
import { trimTextIfLongerThan } from '../../utils/texts'
import { ResourceLinkCell } from '../table/cells'
import { sortByIgnoreCase } from '../../utils/arrays'

/**
 * Component that displays a table of job titles
 */
const JobTitlesList: React.FC<{
  data: JobTitle[]
  itemPath?: string
}> = ({ data, itemPath }) => {
  const tableColumns = useListTableColumns(itemPath)

  return (
    <Table
      columns={tableColumns}
      data={sortByIgnoreCase<JobTitle>(data, 'name')}
      noDataMessage="Aucun Intitulé de Poste à afficher"
    />
  )
}

const useListTableColumns = (itemPath?: string): Column<JobTitle>[] => {
  return React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: data => (
          <ResourceLinkCell
            resource={data.row.original}
            path={itemPath || '/job-titles/'}
            label={data.row.original.name}
          />
        ),
      },
      {
        Header: 'Règles de pointage',
        accessor: 'clockingRule',
        Cell: ({ value }) => value.name,
      },
    ],
    [],
  )
}

export default JobTitlesList
