import React, { ReactNode } from 'react'
import { Element, Form } from 'react-bulma-components'

export interface FormFieldProps {
  label?: string
  error?: string | string[]
  help?: string
  children?: ReactNode
  required?: boolean
  disabled?: boolean
}

const FormField: React.FunctionComponent<FormFieldProps> = ({
  help,
  label,
  children,
  error,
  required,
}) => {
  const classNames = []
  if (required) classNames.push('is-required')
  if (error) classNames.push('is-error')
  return (
    <Form.Field className={classNames.join(' ')}>
      {label && (
        <Form.Label>
          {label}
          {required && (
            <Element
              textColor="danger"
              textWeight="bold"
              renderAs="span"
              data-tooltip="Ce champ est requis"
            >
              {' '}
              *
            </Element>
          )}
        </Form.Label>
      )}
      <Form.Control>{children}</Form.Control>
      {error && <FormFieldErrorMessage error={error} />}
      {help && <Form.Help>{help}</Form.Help>}
    </Form.Field>
  )
}

export default FormField

export const FormFieldErrorMessage: React.FunctionComponent<{
  error?: string | string[]
}> = ({ error }) => {
  if (error) {
    const errors = Array.isArray(error) ? error : [error]
    return (
      <Element textColor="danger" mt={1} textWeight="bold">
        {errors.map((e, i) => (
          <Element renderAs="span" key={e + i}>
            {e} <br />
          </Element>
        ))}
      </Element>
    )
  }
  return <></>
}
