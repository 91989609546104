import React from 'react'
import Layout from '../../components/layout/layout'
import { Columns, Container, Section, Form as BulmaForm } from 'react-bulma-components'
import SelectField from '../../components/form/fields/select'
import { Field, Form, Formik } from 'formik'
import InputField from '../../components/form/fields/input'
import MultiSelectField from '../../components/form/fields/multi-select'
import AutocompleteField from '../../components/form/fields/autocomplete'

const initialErrors = { error: "Détail de l'erreur", multiError: ["Détail de l'erreur"] }

export const DevForms = () => {
  return (
    <>
      <Layout>
        <Section>
          <Container>
            <Formik
              initialValues={{
                value: 'value',
                empty: '',
                multiValue: ['value'],
                error: 'value',
                multiError: ['value'],
              }}
              initialErrors={initialErrors}
              validate={() => initialErrors}
              onSubmit={() => {
                return
              }}
            >
              <Form>
                <Columns>
                  <Columns.Column narrow>
                    <BulmaForm.Field>
                      <BulmaForm.Label>&nbsp;</BulmaForm.Label>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Disabled" />
                      </BulmaForm.Control>
                      <BulmaForm.Help>&nbsp;</BulmaForm.Help>
                    </BulmaForm.Field>
                    <BulmaForm.Field>
                      <BulmaForm.Label>&nbsp;</BulmaForm.Label>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Empty" />
                      </BulmaForm.Control>
                      <BulmaForm.Help>&nbsp;</BulmaForm.Help>
                    </BulmaForm.Field>
                    <BulmaForm.Field>
                      <BulmaForm.Label>&nbsp;</BulmaForm.Label>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Value" />
                      </BulmaForm.Control>
                      <BulmaForm.Help>&nbsp;</BulmaForm.Help>
                    </BulmaForm.Field>
                    <BulmaForm.Field>
                      <BulmaForm.Label>&nbsp;</BulmaForm.Label>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Required + Empty" />
                      </BulmaForm.Control>
                      <BulmaForm.Help>&nbsp;</BulmaForm.Help>
                    </BulmaForm.Field>
                    <BulmaForm.Field>
                      <BulmaForm.Label>&nbsp;</BulmaForm.Label>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Required + Valid" />
                      </BulmaForm.Control>
                      <BulmaForm.Help>&nbsp;</BulmaForm.Help>
                    </BulmaForm.Field>
                    <BulmaForm.Field>
                      <BulmaForm.Label>&nbsp;</BulmaForm.Label>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Required + Error" />
                      </BulmaForm.Control>
                      <BulmaForm.Help>&nbsp;</BulmaForm.Help>
                    </BulmaForm.Field>
                  </Columns.Column>
                  <Columns.Column narrow>
                    <Field
                      component={InputField}
                      value=""
                      label="Texte"
                      help="Informations supplémentaires"
                      disabled
                    />
                    <Field
                      component={InputField}
                      value=""
                      label="Texte"
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={InputField}
                      value="value"
                      label="Texte"
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={InputField}
                      required
                      value=""
                      label="Texte"
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={InputField}
                      required
                      value="value"
                      label="Texte"
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={InputField}
                      required
                      label="Texte"
                      help="Informations supplémentaires"
                      name="error"
                    />
                  </Columns.Column>
                  <Columns.Column narrow>
                    <Field
                      component={SelectField}
                      name="empty"
                      options={[{ value: 'something', label: 'something' }]}
                      label={'Select'}
                      help="Informations supplémentaires"
                      disabled
                    />
                    <Field
                      component={SelectField}
                      name="empty"
                      options={[{ value: 'something', label: 'something' }]}
                      label={'Select'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={SelectField}
                      name="value"
                      options={[{ value: 'value', label: 'value' }]}
                      label={'Select'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={SelectField}
                      options={[]}
                      name="empty"
                      required
                      label={'Select'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={SelectField}
                      options={[{ value: 'value', label: 'value' }]}
                      name="value"
                      required
                      label={'Select'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={SelectField}
                      options={[{ value: 'value', label: 'value' }]}
                      name="error"
                      required
                      label={'Select'}
                      help="Informations supplémentaires"
                    />
                  </Columns.Column>
                  <Columns.Column narrow>
                    <Field
                      component={MultiSelectField}
                      name="empty"
                      value={undefined}
                      options={[{ value: 'value', label: 'value' }]}
                      label={'MultiSelect'}
                      help="Informations supplémentaires zadd"
                      disabled
                    />
                    <Field
                      component={MultiSelectField}
                      name="empty"
                      value={undefined}
                      options={[{ value: 'value', label: 'value' }]}
                      label={'MultiSelect'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={MultiSelectField}
                      name="multiValue"
                      value={undefined}
                      options={[{ value: 'value', label: 'value' }]}
                      label={'MultiSelect'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={MultiSelectField}
                      name="empty"
                      required
                      value={undefined}
                      options={[{ value: 'value', label: 'value' }]}
                      label={'MultiSelect'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={MultiSelectField}
                      name="multiValue"
                      required
                      options={[{ value: 'value', label: 'value' }]}
                      label={'MultiSelect'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={MultiSelectField}
                      name="multiError"
                      required
                      options={[{ value: 'value', label: 'value' }]}
                      label={'MultiSelect'}
                      help="Informations supplémentaires"
                    />
                  </Columns.Column>
                  <Columns.Column narrow>
                    <Field
                      component={AutocompleteField}
                      name="empty"
                      items={[{ value: 'value', label: 'value' }]}
                      label={'AutoComplete'}
                      help="Informations supplémentaires"
                      disabled
                    />
                    <Field
                      component={AutocompleteField}
                      name="empty"
                      items={[{ value: 'value', label: 'value' }]}
                      label={'AutoComplete'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={AutocompleteField}
                      name="value"
                      items={[{ value: 'value', label: 'value' }]}
                      label={'AutoComplete'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={AutocompleteField}
                      name="empty"
                      required
                      items={[{ value: 'value', label: 'value' }]}
                      label={'AutoComplete'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={AutocompleteField}
                      name="value"
                      required
                      items={[{ value: 'value', label: 'value' }]}
                      label={'AutoComplete'}
                      help="Informations supplémentaires"
                    />
                    <Field
                      component={AutocompleteField}
                      name="error"
                      required
                      items={[{ value: 'value', label: 'value' }]}
                      label={'AutoComplete'}
                      help="Informations supplémentaires"
                    />
                  </Columns.Column>
                </Columns>
              </Form>
            </Formik>
          </Container>
        </Section>
      </Layout>
    </>
  )
}
