import React from 'react'
import { Element } from 'react-bulma-components'

interface WorkPeriodEventBenefitDescriptionProps {
  textColor?: string
}

const WorkPeriodEventBenefitDescription: React.FC<WorkPeriodEventBenefitDescriptionProps> = ({
  textColor,
}) => {
  return (
    <>
      <Element renderAs="strong" textSize={7} textColor={textColor}>
        Evénement Prestation
      </Element>{' '}
      : il va affecter positivement les Heures Totales Prestées pour un Jour, quand bien même l’
      Employé n’a pas presté réellement ce Jour là.
      <br />
      <Element renderAs="i">Exemple : Un Congé Payé</Element>
    </>
  )
}

export default WorkPeriodEventBenefitDescription
