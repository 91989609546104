import { TerminalSchema } from '../../../backend/src/services/resources/terminals/terminal.types'
import { TerminalRepositoryCreationPayload } from '../../../backend/src/services/resources/terminals/terminals.repository.service.types'
import api, { ApiResponse } from '.'

export interface Terminal extends TerminalSchema {
  _id: string
}

const POPULATE = ['usagePeriods.employer', 'usagePeriods.interimAgency']

export enum TerminalStatusEnum {
  shipping = 'shipping',
  active = 'active',
  inactive = 'inactive',
}

export const fetch = async (params?: any): Promise<ApiResponse<Terminal[]>> =>
  await api({
    endpoint: '/terminals',
    method: 'get',
    params: {
      sort: '-createdAt',
      populate: POPULATE,
      ...(params || {}),
    },
  })

export const get = async (id: string): Promise<ApiResponse<Terminal>> =>
  await api({
    endpoint: `/terminals/${id}`,
    method: 'get',
    params: {
      populate: POPULATE,
    },
  })

export type TerminalCreationPayload = TerminalRepositoryCreationPayload

export const create = async (params: TerminalCreationPayload): Promise<ApiResponse<Terminal>> =>
  await api({
    endpoint: '/terminals',
    method: 'post',
    params,
  })

export interface TerminalUpdatePayload extends Partial<TerminalCreationPayload> {
  id: string
}

export const update = async (params: TerminalUpdatePayload): Promise<ApiResponse<Terminal>> => {
  const { id, ...payload } = params
  return await api({
    endpoint: `/terminals/${id}`,
    method: 'put',
    params: payload,
  })
}
