import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './employer-settings-wrapper'
import { Box, Element, Heading } from 'react-bulma-components'
import EmployerRulesList from '../../components/employer-rules/list'
import { useEmployerRulesQuery } from '../../queries/employer-rules'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import EmployerRulesExplanation from '../../components/employer-rules/employer-rules-explanation'

/**
 * Component for managing employer financial rules
 */
const EmployerRulesSettings: React.FC = () => {
  const { employer } = useOutletContext<OutletContext>()
  const employerRulesQuery = useEmployerRulesQuery({ employer: employer._id })

  return (
    <>
      <SidebarContentTitle
        title="Règles financières"
        help={<EmployerRulesExplanation textColor={'white'} />}
      />
      <Box mb={1}>
        <EmployerRulesList employerRules={employerRulesQuery.data || []} employer={employer} />
      </Box>
      <Element renderAs="p" textColor="grey">
        Pour toute modification ou ajout de règles financières, veuillez contacter votre
        administrateur TeamTim.
      </Element>
    </>
  )
}

export default EmployerRulesSettings
