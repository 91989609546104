import { Field, Form, Formik } from 'formik'
import React from 'react'
import InputField from '../form/fields/input'
import { useUpdateMutation } from '../../queries/job-titles'
import { JobTitle } from '../../api/job-titles'
import { Columns, Element, Heading } from 'react-bulma-components'
import { RequestButton } from '../request-components/request-components'
import { InterimAgency } from '../../api/organizations'
import { compareDocsById } from '../../utils/documents'
import { getJobTitleLaborCoefficientByInterimAgency } from '../../utils/job-titles'

interface JobTitleLaborCoefficientFormProps {
  jobTitle: JobTitle
  interimAgency: InterimAgency
}

const JobTitleLaborCoefficientForm: React.FC<JobTitleLaborCoefficientFormProps> = ({
  jobTitle,
  interimAgency,
}) => {
  const updateMutation = useUpdateMutation()
  const laborCostCoefficient = getJobTitleLaborCoefficientByInterimAgency(jobTitle, interimAgency)

  return (
    <Formik
      initialValues={{
        delegationValue: laborCostCoefficient?.delegationValue,
        managementValue: laborCostCoefficient?.managementValue,
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.delegationValue) errors.delegationValue = 'Champ requis'
        if (!values.managementValue) errors.managementValue = 'Champ requis'
        return errors
      }}
      onSubmit={values => {
        const laborCostCoefficients = jobTitle.laborCostCoefficients.map(l => {
          if (compareDocsById(l.interimAgency, interimAgency)) {
            l.delegationValue = values.delegationValue as number
            l.managementValue = values.managementValue as number
          }
          return l
        })
        updateMutation.mutate({
          id: jobTitle._id,
          laborCostCoefficients,
        })
      }}
    >
      {() => {
        return (
          <Form autoComplete="off">
            <Heading size={6} mb={3}>
              {jobTitle.name} / {interimAgency.name}
            </Heading>
            <Columns>
              <Columns.Column>
                <Field
                  label={'Délégation'}
                  name={`delegationValue`}
                  component={InputField}
                  type="number"
                  required
                  step="0.1"
                />
              </Columns.Column>
              <Columns.Column>
                <Field
                  label={'Gestion'}
                  name={`managementValue`}
                  component={InputField}
                  type="number"
                  required
                  step="0.1"
                />
              </Columns.Column>
            </Columns>
            <Element my={2}>
              <RequestButton color="primary" type="submit" mutation={updateMutation}>
                Mettre à jour
              </RequestButton>
            </Element>
          </Form>
        )
      }}
    </Formik>
  )
}
export default JobTitleLaborCoefficientForm
