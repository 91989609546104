import React from 'react'
import { Element } from 'react-bulma-components'

/**
 * Component that explains the concept of organization members and their roles
 * @param {Object} props - Component props
 * @param {string} props.textColor - Color of the text (optional)
 */
interface EmployerMemberExplanationProps {
  textColor?: string
}

const EmployerMemberExplanation: React.FC<EmployerMemberExplanationProps> = ({ textColor }) => {
  return (
    <>
      <Element mb={2}>
        Les gestionnaires de votre entreprise sont les utilisateurs qui ont accès à votre espace
        entreprise sur la plateforme. Chaque membre possède un rôle qui définit ses permissions :
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Administrateur
        </Element>
        <Element>
          Accès complet à toutes les fonctionnalités de la plateforme. Peut gérer les gestionnaires
          de l'entreprise, les missions, et modifier les paramètres de l'entreprise.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Gestionnaire
        </Element>
        <Element>
          Peut gérer les missions et les équipes au quotidien. A accès aux fonctionnalités
          opérationnelles mais ne peut pas modifier les paramètres de l'entreprise.
        </Element>
      </Element>

      <Element mb={3}>
        <Element renderAs="strong" textColor={textColor}>
          Profil Pointage
        </Element>
        <Element>
          Accès limité à la fonctionnalité de pointage uniquement. Ne peut pas accéder aux autres
          fonctionnalités de la plateforme.
        </Element>
      </Element>

      <Element>
        <Element renderAs="i" textSize={7}>
          La modification des rôles des gestionnaires ne peut être effectuée que par un
          administrateur de l'entreprise.
        </Element>
      </Element>
    </>
  )
}

export default EmployerMemberExplanation
