import { Field, Form, Formik } from 'formik'
import React from 'react'
import InputField from '../form/fields/input'
import { RequestButton } from '../request-components/request-components'
import {
  useCreateAppSubscriptionPeriodMutation,
  useCreateAppSubscriptionPeriodMutationFromTotal,
  useUpdateAppSubscriptionPeriodMutationFromTotal,
} from '../../queries/app-subscription-periods'
import { AppSubscriptionPeriodsInterimAgencyTotal } from '../../api/app-subscription-periods'
import TextareaField from '../form/fields/textarea'

interface AppSubscriptionPeriodValidationFormProps {
  periodTotal: AppSubscriptionPeriodsInterimAgencyTotal
  onSuccess?: () => void
}

const AppSubscriptionPeriodValidationForm: React.FC<AppSubscriptionPeriodValidationFormProps> = ({
  periodTotal,
  onSuccess,
}) => {
  const createMutation = useCreateAppSubscriptionPeriodMutationFromTotal(periodTotal)
  const updateMutation = useUpdateAppSubscriptionPeriodMutationFromTotal(periodTotal)

  return (
    <Formik<{ invoiceRef?: string; comment?: string }>
      initialValues={{
        invoiceRef: periodTotal.invoiceRef,
        comment: periodTotal.comment,
      }}
      onSubmit={async values => {
        if (periodTotal.employerDetails[0]?.appSubscriptionPeriodId) {
          await updateMutation(values)
        } else {
          await createMutation(values)
        }
        onSuccess?.()
      }}
    >
      <Form>
        <Field label="Référence de facture" name="invoiceRef" component={InputField} />
        <Field label="Commentaire" name="comment" component={TextareaField} />
        <RequestButton color="primary" type="submit" mt={4}>
          Valider la période
        </RequestButton>
      </Form>
    </Formik>
  )
}

export default AppSubscriptionPeriodValidationForm
