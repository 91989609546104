import React, { useEffect, useState } from 'react'
import { FieldProps } from 'formik'
import { Form, Icon } from 'react-bulma-components'
import FormField, { FormFieldProps } from './form-field'
import { useCombobox } from 'downshift'
import { get } from 'lodash'
import { SearchIcon } from '../../icons'

export interface AutocompleteFieldItem {
  value: string
  label: string
  renderedLabel?: React.ReactNode
  [key: string]: any
}

interface AutocompleteFieldProps extends FormFieldProps, FieldProps {
  type: string
  items: AutocompleteFieldItem[]
  onlySelect?: boolean
  disabled?: boolean
  required?: boolean
  placeholder?: string
  clearOnSelect?: boolean
  showItemsAfterSelect?: boolean
}

const AutocompleteField: React.FunctionComponent<AutocompleteFieldProps> = ({
  field,
  form,
  items,
  label,
  disabled = false,
  help,
  required,
  onlySelect,
  placeholder,
  clearOnSelect,
  showItemsAfterSelect,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
    reset,
    highlightedIndex,
    setInputValue,
    inputValue,
  } = useCombobox<AutocompleteFieldItem>({
    items,
    defaultInputValue: get(form.initialValues, field.name),
    onSelectedItemChange: changes => {
      const selectedItem = filteredItems[highlightedIndex]

      form.setFieldValue(field.name, selectedItem?.value || (!onlySelect && changes.inputValue))
      if (clearOnSelect) {
        setInputValue('')
      } else {
        setInputValue(selectedItem?.label || '')
      }
      if (showItemsAfterSelect) {
        inputRef?.current?.blur()
        inputRef?.current?.focus()
      }
    },
    itemToString: item => (item ? item.label : ''),
    onInputValueChange({ inputValue }) {
      !onlySelect && form.setFieldValue(field.name, inputValue)
    },
  })

  const inputProps = {
    className: 'input',
    onFocus: () => !isOpen && items[0] && openMenu(),
  }

  useEffect(() => {
    reset()
  }, [form.initialValues])

  const filteredItems = React.useMemo(
    () =>
      items.filter(
        item => !inputValue || item.label.toLowerCase().includes(inputValue?.toLowerCase() || ''),
      ),
    [items, inputValue],
  )

  return (
    <FormField
      label={label}
      error={form.errors[field.name] as string}
      required={required}
      help={help}
    >
      <Icon align="left" p={2}>
        <SearchIcon />
      </Icon>
      <div {...getComboboxProps()}>
        <input
          {...getInputProps({ ...inputProps, disabled, ref: inputRef, required })}
          placeholder={placeholder}
          name={field.name}
          data-lpignore="true"
          className="input"
        />
      </div>
      <div {...getMenuProps()}>
        {isOpen && (
          <ul
            style={{
              maxHeight: `250px`,
              overflow: `scroll`,
              position: `absolute`,
              zIndex: 10,
              width: `100%`,
            }}
            className="filter-dropdown"
          >
            {filteredItems.map((item, index) => {
              const filteredItemIndex = filteredItems.findIndex(i => i.value === item.value)
              return (
                <li
                  key={`${item.value}-${item.field}-${item.count}`}
                  {...getItemProps({
                    item,
                    index: filteredItemIndex,
                    className: [
                      `py-2 has-text-left pl-2 pr-1`,
                      highlightedIndex === filteredItemIndex
                        ? `has-background-primary`
                        : `has-background-white`,
                    ].join(' '),
                  })}
                >
                  {item.renderedLabel || item.label}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </FormField>
  )
}

export default AutocompleteField
