import React from 'react'
import { FiltersResourcesEnum, KPIFilters } from '../../store/filters'
import useFilters from '../../hooks/filters'
import { Formik } from 'formik'
import { Button, Columns, Element, Form } from 'react-bulma-components'
import AutoSubmit from '../form/utils/auto-submit'
import OrganizationFilterField from '../filters/fields/organization'
import { OrganizationTypeEnum } from '../../api/organizations'
import useStore from '../../store'
import WorkersFilterField from '../filters/fields/workers'
import DateFilterField from '../filters/fields/date'
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isBefore,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns'
import { formatISODate } from '../../utils/date'
import ServiceFilterField from '../filters/fields/service'

const KPIFilters: React.FC = ({}) => {
  const { setFilters, filters } = useFilters(FiltersResourcesEnum.kPI)
  const currentOrganization = useStore(s => s.session.currentOrganization)

  const oneYearAgo = formatISODate(subYears(new Date(), 1))
  return (
    <Formik<KPIFilters>
      initialValues={{
        ...filters,
        start: filters.start,
        end: filters.end,
      }}
      enableReinitialize
      validate={values => {
        if (isBefore(parseISO(values.start), parseISO(oneYearAgo)))
          return { start: 'Vous pouvez retourner maximum un an en arrière' }
        if (isBefore(parseISO(values.end), parseISO(values.start)))
          return { start: 'Intervalle de dates invalide' }
        if (differenceInDays(parseISO(values.end), parseISO(values.start)) > 93)
          return { start: "Maximum 3 mois d'intervalle" }
        return {}
      }}
      onSubmit={values => {
        const payload = {
          ...values,
          start: values.start,
          end: values.end,
        }
        setFilters(FiltersResourcesEnum.kPI, payload)
      }}
    >
      {props => {
        const startDate = parseISO(filters.start)
        return (
          <>
            <Columns>
              <Columns.Column size={7}>
                <Element display="flex" justifyContent="center">
                  <DateFilterField
                    name="start"
                    label="Date début"
                    max={filters.end}
                    min={oneYearAgo}
                  />
                  <DateFilterField name="end" label="Date fin" min={filters.start} />
                </Element>
                <Element display="flex">
                  <WorkersFilterField />
                  {currentOrganization?.type === 'employer' ? (
                    <OrganizationFilterField type={OrganizationTypeEnum.interimAgency} />
                  ) : (
                    <OrganizationFilterField type={OrganizationTypeEnum.employer} />
                  )}
                  <ServiceFilterField />
                </Element>
              </Columns.Column>
              <Columns.Column>
                <Columns centered>
                  <Columns.Column narrow>
                    <Form.Label>Naviguer par période</Form.Label>
                    <Form.Field kind="addons" justifyContent="center">
                      <Form.Control>
                        <Button
                          color={'ghost'}
                          onClick={() =>
                            setFilters(FiltersResourcesEnum.kPI, {
                              ...filters,
                              start: formatISODate(subDays(startDate, 1)),
                              end: formatISODate(subDays(startDate, 1)),
                            })
                          }
                        >
                          Jour précédent
                        </Button>
                      </Form.Control>
                      <Form.Control>
                        <Button
                          color={'primary'}
                          textColor="dark"
                          outlined
                          onClick={() =>
                            setFilters(FiltersResourcesEnum.kPI, {
                              ...filters,
                              start: formatISODate(startOfDay(new Date())),
                              end: formatISODate(endOfDay(new Date())),
                            })
                          }
                        >
                          Aujourd'hui
                        </Button>
                      </Form.Control>
                      <Form.Control>
                        <Button
                          color={'ghost'}
                          onClick={() =>
                            setFilters(FiltersResourcesEnum.kPI, {
                              ...filters,
                              start: formatISODate(addDays(startDate, 1)),
                              end: formatISODate(addDays(startDate, 1)),
                            })
                          }
                        >
                          Jour suivant
                        </Button>
                      </Form.Control>
                    </Form.Field>
                    <Form.Field kind="addons" justifyContent="center">
                      <Form.Control>
                        <Button
                          color={'ghost'}
                          onClick={() =>
                            setFilters(FiltersResourcesEnum.kPI, {
                              ...filters,
                              start: formatISODate(subWeeks(startOfWeek(startDate), 1)),
                              //start: subWeeks(startOfWeek(filters.start), 1),
                              end: formatISODate(subWeeks(endOfWeek(startDate), 1)),
                            })
                          }
                        >
                          Semaine Précédente
                        </Button>
                      </Form.Control>
                      <Form.Control>
                        <Button
                          color={'primary'}
                          textColor="dark"
                          outlined
                          onClick={() =>
                            setFilters(FiltersResourcesEnum.kPI, {
                              ...filters,
                              start: formatISODate(startOfWeek(new Date())),
                              end: formatISODate(endOfWeek(new Date())),
                            })
                          }
                        >
                          Cette Semaine
                        </Button>
                      </Form.Control>
                      <Form.Control>
                        <Button
                          color={'ghost'}
                          onClick={() =>
                            setFilters(FiltersResourcesEnum.kPI, {
                              ...filters,
                              start: formatISODate(addWeeks(startOfWeek(startDate), 1)),
                              end: formatISODate(addWeeks(endOfWeek(startDate), 1)),
                            })
                          }
                        >
                          Semaine Suivante
                        </Button>
                      </Form.Control>
                    </Form.Field>
                    <Form.Field kind="addons" justifyContent="center">
                      <Form.Control>
                        <Button
                          color={'ghost'}
                          onClick={() =>
                            setFilters(FiltersResourcesEnum.kPI, {
                              ...filters,
                              start: formatISODate(subMonths(startOfMonth(startDate), 1)),
                              //start: subWeeks(startOfWeek(filters.start), 1),
                              end: formatISODate(subMonths(endOfMonth(startDate), 1)),
                            })
                          }
                        >
                          Mois Précédent
                        </Button>
                      </Form.Control>
                      <Form.Control>
                        <Button
                          color={'primary'}
                          outlined
                          textColor="dark"
                          onClick={() =>
                            setFilters(FiltersResourcesEnum.kPI, {
                              ...filters,
                              start: formatISODate(startOfMonth(new Date())),
                              end: formatISODate(endOfMonth(new Date())),
                            })
                          }
                        >
                          Ce mois
                        </Button>
                      </Form.Control>
                      <Form.Control>
                        <Button
                          color={'ghost'}
                          onClick={() =>
                            setFilters(FiltersResourcesEnum.kPI, {
                              ...filters,
                              start: formatISODate(addMonths(startOfMonth(startDate), 1)),
                              end: formatISODate(addMonths(endOfMonth(startDate), 1)),
                            })
                          }
                        >
                          Mois Suivant
                        </Button>
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
            </Columns>

            <AutoSubmit values={props.values} errors={props.errors} submitForm={props.submitForm} />
          </>
        )
      }}
    </Formik>
  )
}

export default KPIFilters
