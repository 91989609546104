import React from 'react'
import { EmployerRule } from '../../api/employer-rules'
import { Column } from 'react-table'
import { ResourceLinkCell } from '../table/cells'
import Table from '../table'
import { sortByIgnoreCase } from '../../utils/arrays'
import EmployerRuleDescription from './employer-rule-description'
import Protected from '../protected/protected'
import { CheckIcon } from '../icons'
import { Icon } from 'react-bulma-components'
import { Employer } from '../../api/organizations'

interface EmployerRulesListProps {
  employerRules: EmployerRule[]
  employer?: Employer
}

const EmployerRulesList: React.FC<EmployerRulesListProps> = ({ employerRules, employer }) => {
  const tableColumns = useRulesTableColumns(employer?._id)

  return (
    <Table
      columns={tableColumns}
      data={sortByIgnoreCase<EmployerRule>(employerRules, 'name')}
      noDataMessage="Aucune Règle Financière à afficher"
    />
  )
}

const useRulesTableColumns = (employerId?: string): Column<EmployerRule>[] => {
  return React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: data => (
          <>
            <Protected roles={['superAdmin']}>
              <ResourceLinkCell
                resource={data.row.original}
                path={`/employers/${employerId}/rules/`}
                label={data.row.original.name}
              />
            </Protected>
            <Protected roles={['employerMember']}>{data.row.original.name}</Protected>
          </>
        ),
      },
      {
        Header: 'Nom court',
        accessor: 'shortName',
      },
      {
        Header: 'Description',
        accessor: 'interval.period',
        Cell: data => <EmployerRuleDescription employerRule={data.row.original} />,
      },
      {
        Header: 'Récap.',
        accessor: 'display.summary',
        Cell: data => {
          const employerRule = data.row.original as EmployerRule
          if (employerRule.display.summary)
            return (
              <Icon>
                <CheckIcon />
              </Icon>
            )
          return null
        },
      },
      {
        Header: 'Paie',
        accessor: 'display.payroll',
        Cell: data => {
          const employerRule = data.row.original as EmployerRule
          if (employerRule.display.payroll)
            return (
              <Icon>
                <CheckIcon />
              </Icon>
            )
          return null
        },
      },
    ],
    [],
  )
}

export default EmployerRulesList
