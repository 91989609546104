import React from 'react'
import { Button, Icon } from 'react-bulma-components'
import { EditIcon } from '../../icons'
import { useModal } from '../../modal'
import UpdateUserModal from '../modals/update-user'
import { User, UserRoleKind } from '../../../api/users'
import { ButtonProps } from '../../../types/bulma'

interface EditUserButtonProps {
  user: User
  userRoleKind: UserRoleKind
  label?: string
  color?: ButtonProps['color']
}

const EditUserButton: React.FC<EditUserButtonProps> = ({ user, userRoleKind, label, color }) => {
  const modal = useModal()
  return (
    <>
      <Button
        size="small"
        color={color}
        mr={'2'}
        onClick={() => {
          modal.setIsDisplayed(true)
        }}
        data-test="edit-worker-btn"
      >
        <Icon mr={label ? 1 : 0}>
          <EditIcon />
        </Icon>
        {label}
      </Button>
      <UpdateUserModal user={user} modalActions={modal} userRole={userRoleKind} />
    </>
  )
}

export default EditUserButton
