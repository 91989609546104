import { EmployerRule } from '../api/employer-rules'

/**
 * Récupère la valeur courante d'une règle employeur
 * @param valuesInTime Liste des valeurs dans le temps
 * @returns La valeur courante ou undefined si aucune valeur n'est active
 */
export const getCurrentValue = (
  valuesInTime: EmployerRule['valuesInTime'],
): EmployerRule['valuesInTime'][0]['value'] | undefined =>
  valuesInTime.find(vt => !vt.effectiveTo || new Date(vt.effectiveTo) > new Date())?.value
