import { Document } from 'mongoose'
import { ResourceRefOrSchema } from '../../../types/common.types'
import { EmployerSchema, InterimAgencySchema } from '../organizations/organization.model'

export enum TerminalStatusEnum {
  active = 'active',
  inactive = 'inactive',
}

export enum TerminalConnectionStatusEnum {
  online = 'online',
  offline = 'offline',
}

export interface UsagePeriod {
  _id?: string
  startDate: Date
  endDate?: Date
  employer: ResourceRefOrSchema<EmployerSchema>
  interimAgency: ResourceRefOrSchema<InterimAgencySchema>
  rentalCost: number
  createdAt?: Date
  updatedAt?: Date
}

export interface TerminalDevice {
  name: string
  serialNumber: string
}

export interface TerminalSchema extends Document {
  device: TerminalDevice
  status: TerminalStatusEnum
  connectionStatus: TerminalConnectionStatusEnum
  lastConnectedAt?: Date
  usagePeriods: UsagePeriod[]
  createdAt?: Date
  updatedAt?: Date
}
