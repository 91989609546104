import React from 'react'
import { User, UserRoleKind } from '../../../api/users'
import useUsersTableData, { UserListRowKeys } from './hooks/use-users-table-data'
import Table from '../../table'

interface UsersTableProps {
  users: User[]
  roleKind: UserRoleKind | 'offlineWorker'
}

const UsersTable: React.FC<UsersTableProps> = ({ users, roleKind }) => {
  const { columns, users: usersAsRows } = useUsersTableData(
    users,
    roleKind === 'offlineWorker' ? 'worker' : roleKind,
    columnsByRoles[roleKind],
  )

  return (
    <Table
      columns={columns}
      data={usersAsRows}
      noDataMessage="Aucun Utilisateur à afficher"
      tableClassName="overflow-visible"
      className="overflow-visible"
    />
  )
}

export default UsersTable

const columnsByRoles: Record<UsersTableProps['roleKind'], UserListRowKeys[]> = {
  worker: [
    'firstName',
    'lastName',
    'internalRef',
    //'internalComment',
    //'internalInformation',
    'email',
    'associationStatus',
    //'availability',
    'weeklyHoursWorked',
    'monthlyHoursWorked',
    //'editUser',
    //'resendInvitation',
    //'discontinueAssociation',
    'actions',
  ],
  offlineWorker: [
    'firstName',
    'lastName',
    'internalRef',
    'weeklyHoursWorked',
    'monthlyHoursWorked',
    'qrCode',
    //'editUser',
    //'discontinueAssociation',
    'actions',
  ],
  employerMember: [
    'firstName',
    'lastName',
    'email',
    'organizationRole',
    'roleStatus',
    //'editUser',
    //'resendInvitation',
    //'discontinueAssociation',
    'actions',
  ],
  interimAgencyMember: [
    'firstName',
    'lastName',
    'email',
    'roleStatus',
    //'resendInvitation',
    //'discontinueAssociation',
    'actions',
  ],
  superAdmin: ['firstName', 'lastName', 'email', 'resendInvitation', 'actions'],
}
