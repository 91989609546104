import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './interim-agency-settings-wrapper'
import { useTerminalsQuery } from '../../queries/terminals'
import TerminalsList from '../../components/terminals/list'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import { Box } from 'react-bulma-components'
import TerminalExplanation from '../../components/terminals/terminal-explanation'

/**
 * Displays the list of terminals for an interim agency
 */
const TerminalsSettings: React.FC = () => {
  const { interimAgency } = useOutletContext<OutletContext>()
  const { data: terminals } = useTerminalsQuery({
    enabled: Boolean(interimAgency),
  })

  return (
    <>
      <SidebarContentTitle title="Bornes de Pointage" />
      <Box>
        <TerminalsList data={terminals || []} />
      </Box>
    </>
  )
}

export default TerminalsSettings
