import React from 'react'
import { Element } from 'react-bulma-components'
import { TermsOfUseTitle2, TermsOfUseTitle3, TermsOfUseTitle4 } from '.'

export const InterimAgencyTermsOfUse: React.FC = () => {
  return (
    <Element className="content">
      <TermsOfUseTitle2>
        Conditions Générales d'Utilisation pour les Agences d'Intérim
      </TermsOfUseTitle2>
      <TermsOfUseTitle3>1. Acceptation des Conditions Générales d'Utilisation</TermsOfUseTitle3>
      <TermsOfUseTitle4>1.1 Acceptation des CGU : </TermsOfUseTitle4>En utilisant l'application [nom
      de l'application], vous acceptez les présentes Conditions Générales d'Utilisation et vous vous
      engagez à les respecter.
      <TermsOfUseTitle3>2. Inscription et Utilisation de l'Application</TermsOfUseTitle3>
      <TermsOfUseTitle4>2.1 Processus d'Inscription : </TermsOfUseTitle4>Les agences d'intérim
      doivent s'inscrire sur l'application en fournissant les informations nécessaires pour créer un
      compte d'agence.
      <TermsOfUseTitle4>2.2 Accès à l'Application :</TermsOfUseTitle4> Une fois inscrites, les
      agences d'intérim peuvent accéder à l'application en utilisant leurs identifiants de
      connexion.
      <TermsOfUseTitle3>3. Gestion des Intérimaires et des Missions</TermsOfUseTitle3>
      <TermsOfUseTitle4>3.1 Ajout des Intérimaires :</TermsOfUseTitle4> Les agences d'intérim
      peuvent ajouter les intérimaires associés à leur agence dans l'application en fournissant
      leurs noms, prénoms et adresses e-mail. Les agences sont responsables de l'obtention du
      consentement des intérimaires pour l'utilisation de leurs données personnelles dans
      l'application.
      <TermsOfUseTitle4>3.2 Liaison Agence/Intérimaire : </TermsOfUseTitle4>Les agences d'intérim
      doivent informer et obtenir l'approbation des intérimaires concernant leur ajout dans
      l'application. Les intérimaires doivent accepter la liaison avec l'agence pour recevoir des
      missions et utiliser les fonctionnalités de l'application.
      <TermsOfUseTitle4>3.3 Création et Gestion des Missions : </TermsOfUseTitle4>Les agences
      d'intérim peuvent créer et gérer les missions proposées aux intérimaires sur l'application.
      Les agences sont responsables de la validation des missions créées par les employeurs et
      acceptées par les intérimaires.
      <TermsOfUseTitle3>4. Accès aux Données des Intérimaires et des Missions</TermsOfUseTitle3>
      <TermsOfUseTitle4>4.1 Accès aux Données des intérimaires : </TermsOfUseTitle4>Les agences
      d'intérim ont accès aux informations des intérimaires associés à leur agence, telles que les
      noms, prénoms et heures de pointage, dans le respect des lois et réglementations applicables
      en matière de confidentialité des données.
      <TermsOfUseTitle4>4.2 Accès aux Données des Missions : </TermsOfUseTitle4>Les agences
      d'intérim ont accès aux informations des missions créées sur l'application, telles que les
      détails des employeurs et les actions associées aux missions, comme les arrêts de mission.
      <TermsOfUseTitle4>
        4.3 La communication entre les agences et les intérimaires se fait en dehors de
        l'application.
      </TermsOfUseTitle4>
      TeamTIM ne peut être tenu responsable du contenu ou de la gestion de ces communications.
      <TermsOfUseTitle4>
        4.4 Les agences d'intérim sont responsables de toutes les obligations légales,
        contractuelles ou réglementaires liées à l'emploi des intérimaires, y compris les conditions
        de travail, les salaires, les avantages sociaux, etc.
      </TermsOfUseTitle4>
      <TermsOfUseTitle3>5. Communication avec les Intérimaires</TermsOfUseTitle3>
      <TermsOfUseTitle4>5.1 Communication Hors de l'Application :</TermsOfUseTitle4> Les agences
      d'intérim communiquent directement avec les intérimaires en dehors de l'application. Les
      agences sont responsables de la gestion de ces communications et de l'information des
      intérimaires concernant les mises à jour et les changements liés aux missions.
      <TermsOfUseTitle3>6. Support Client et Assistance</TermsOfUseTitle3>
      <TermsOfUseTitle4>6.1 Contact du Support :</TermsOfUseTitle4> Pour toute question ou problème
      lié à l'application, les agences d'intérim peuvent contacter le support client par e-mail à
      support@teamtim.fr.
      <TermsOfUseTitle3>7. Politique de Confidentialité</TermsOfUseTitle3>
      <TermsOfUseTitle4>7.1 Protection des Données :</TermsOfUseTitle4> Les agences d'intérim
      s'engagent à traiter les données personnelles des intérimaires conformément aux lois et
      réglementations applicables en matière de protection des données. Les détails sur la collecte,
      l'utilisation et le partage des données sont décrits en détail dans la politique de
      confidentialité disponible sur l'application.
      <TermsOfUseTitle4>7.2 Conservation des Données :</TermsOfUseTitle4> Les données des
      intérimaires et des missions sont conservées conformément aux durées légales requises, ainsi
      qu'aux obligations de facturation et de conservation des données. Les agences d'intérim
      doivent respecter les dispositions légales en matière de conservation et de suppression des
      données.
      <TermsOfUseTitle4>7.3 Sécurité des Données :</TermsOfUseTitle4> Les agences d'intérim mettent
      en place des mesures de sécurité appropriées pour protéger les données des intérimaires et des
      missions contre tout accès, utilisation ou divulgation non autorisé. Cela comprend des mesures
      techniques, administratives et physiques pour prévenir les violations de données et garantir
      la confidentialité des informations.
      <TermsOfUseTitle4>7.4 Partage de Données :</TermsOfUseTitle4> Les agences d'intérim s'engagent
      à ne pas partager les données des intérimaires avec des tiers non autorisés, sauf dans les cas
      prévus par la loi ou avec le consentement des intérimaires. Les données peuvent être partagées
      avec les employeurs concernés dans le cadre de la gestion des missions, conformément aux
      autorisations accordées par les intérimaires.
      <TermsOfUseTitle4>7.5 Droits des Intérimaires :</TermsOfUseTitle4> Les intérimaires ont des
      droits sur leurs données personnelles, y compris le droit d'accéder, de rectifier, de limiter
      l'utilisation, de s'opposer et de supprimer leurs données. Les agences d'intérim doivent
      fournir aux intérimaires les moyens appropriés pour exercer ces droits et répondre à leurs
      demandes.
    </Element>
  )
}
