import React from 'react'
import { Button, Container, Icon, Level, Section, Box, Columns } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import {
  useAppSubscriptionPeriodByPeriodQuery,
  useAppSubscriptionPeriodQuery,
} from '../../queries/app-subscription-periods'
import useUrlId from '../../hooks/use-url-id'
import { ViewListIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import PageTitle from '../../components/pages/page-title'
import { Employer, InterimAgency } from '../../api/organizations'
import { getWeek, localDate } from '../../utils/date'
import { getYear } from 'date-fns'
import useStore from '../../store'
import { useAssociatedOrganizationsQuery } from '../../queries/organizations'
import AppSubscriptionPeriodTable from '../../components/app-subscription-periods/app-subscription-period-table'

/**
 * Page de détails d'une période d'abonnement
 */
const AppSubscriptionPeriodDetails: React.FC = () => {
  const currentOrganization = useStore(state => state.session.currentOrganization as InterimAgency)
  const { id, isNew } = useUrlId()
  const associatedOrganizationsQuery = useAssociatedOrganizationsQuery()

  const { data: inProgressPeriod } = useAppSubscriptionPeriodByPeriodQuery(
    {
      periodNumber: getWeek(new Date()),
      periodYear: getYear(new Date()),
      interimAgencyId: currentOrganization._id,
      employerId: (associatedOrganizationsQuery.data?.[0] as Employer)?._id,
    },
    { enabled: Boolean(isNew && associatedOrganizationsQuery.data?.[0] && currentOrganization) },
  )

  const { data: existingPeriod } = useAppSubscriptionPeriodQuery(id, {
    enabled: Boolean(id && !isNew),
  })

  const period = isNew ? inProgressPeriod : existingPeriod

  if (!period) return <></>

  const employer = period.employer as Employer
  const interimAgency = period.interimAgency as InterimAgency

  return (
    <Layout>
      <Section>
        <Container max={true}>
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PageTitle
                title={`Détails de la Période d'Abonnement`}
                subTitle={period.identifier || 'Période en cours'}
              />
            </Level.Side>
            <Level.Side>
              <Button
                renderAs={Link}
                to="/app-subscription-periods"
                outlined
                color="primary"
                size={'small'}
              >
                <Icon>
                  <ViewListIcon />
                </Icon>
                <span>Historique des Périodes d'Abonnement</span>
              </Button>
            </Level.Side>
          </Level>
          <Box className="mb-5">
            <Columns mb={1}>
              <Columns.Column size={6}>
                <strong>Ref Client:</strong> {interimAgency?.name}
              </Columns.Column>
              <Columns.Column size={6}>
                <strong>N° de Période:</strong> {period.identifier || 'Période en cours'}
              </Columns.Column>
            </Columns>
            <Columns mb={1}>
              <Columns.Column size={6}>
                <strong>Entreprise Utilisatrice:</strong> {employer?.name}
              </Columns.Column>
              <Columns.Column size={6}>
                <strong>Période:</strong> du {localDate(period.periodStart)} au{' '}
                {localDate(period.periodEnd)}
              </Columns.Column>
            </Columns>
            <Columns mb={1}>
              <Columns.Column size={6}>
                <strong>N° de Facture:</strong> {period.invoiceRef}
              </Columns.Column>
              <Columns.Column size={6}>
                <strong>Commentaire:</strong> {period.comment}
              </Columns.Column>
            </Columns>
          </Box>
          <Box>
            <AppSubscriptionPeriodTable period={period} />
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export default AppSubscriptionPeriodDetails
