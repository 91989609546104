import React from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { OutletContext } from './employer-settings-wrapper'
import { useJobTitlesQuery } from '../../queries/job-titles'
import JobTitlesList from '../../components/job-titles/list'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import { Box } from 'react-bulma-components'
import JobTitleExplanation from '../../components/job-titles/job-title-explanation'
import { AddResourceIcon } from '../../components/icons'

/**
 * Component for managing employer job titles
 */
const JobTitlesSettings: React.FC = () => {
  const { employer } = useOutletContext<OutletContext>()
  const { data: jobTitles } = useJobTitlesQuery({
    enabled: Boolean(employer),
  })
  const navigate = useNavigate()

  return (
    <>
      <SidebarContentTitle
        title="Intitulés de Poste"
        onButtonClick={() => navigate('/settings/job-titles/new')}
        addButtonLabel="Nouvel intitulé de poste"
        ButtonIcon={AddResourceIcon}
        help={<JobTitleExplanation textColor={'white'} />}
      />
      <Box>
        <JobTitlesList data={jobTitles || []} itemPath={`/settings/job-titles/`} />
      </Box>
    </>
  )
}

export default JobTitlesSettings
