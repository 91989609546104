import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Panel } from 'react-bulma-components'
import { useTerminalQuery } from '../../queries/terminals'
import { formatDate } from '../../utils/date'

const TerminalDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data: terminal, isLoading } = useTerminalQuery(id)

  if (isLoading) {
    return <div>Chargement...</div>
  }

  if (!terminal) {
    return <div>Terminal non trouvé</div>
  }

  return (
    <Container>
      <h1 className="title">Détails du terminal</h1>

      <Panel>
        <Panel.Block>
          <strong className="mr-2">ID:</strong> {terminal._id}
        </Panel.Block>

        <Panel.Block>
          <strong className="mr-2">Créé le:</strong>
          {formatDate(terminal.createdAt)}
        </Panel.Block>

        <Panel.Block>
          <strong className="mr-2">Employeur actuel:</strong>
          {terminal.usagePeriods?.[0]?.employer?.name || 'Non assigné'}
        </Panel.Block>

        <Panel.Block>
          <strong className="mr-2">Historique d'utilisation:</strong>
          <ul>
            {terminal.usagePeriods?.map((period, index) => (
              <li key={index}>
                {period.employer?.name} - Du {formatDate(period.startedAt)}
                {period.endedAt ? ` au ${formatDate(period.endedAt)}` : ' (en cours)'}
              </li>
            ))}
          </ul>
        </Panel.Block>
      </Panel>
    </Container>
  )
}

export default TerminalDetails
