import React from 'react'
import { Element } from 'react-bulma-components'
import { UserRoleKind } from '../../api/users'
import { userRoleKindThemeNames } from '../../hooks/use-theme-switcher'
import { useUsersQuery } from '../../queries/users'
import { DashboardCard, DashboardCardProps } from './dashboard-card'
import WorkerLabel from '../texts/worker-label'

const labels: {
  [key in UserRoleKind | 'users']: Pick<DashboardCardProps, 'title' | 'mainStat' | 'to'>
} = {
  worker: {
    title: 'INTÉRIM',
    mainStat: {
      label: <WorkerLabel plural />,
      value: 0,
    },
    to: '/settings/workers',
  },
  interimAgencyMember: {
    title: "AGENCES D'INTÉRIM",
    mainStat: {
      label: 'Agents',
      value: 0,
    },
  },
  employerMember: {
    title: 'EMPLOYEURS',
    mainStat: {
      label: 'Employés',
      value: 0,
    },
  },
  superAdmin: {
    title: 'SUPER ADMINS',
    mainStat: {
      label: 'Super Admins',
      value: 0,
    },
  },
  users: {
    title: 'PLATEFORME',
    mainStat: {
      label: 'Utilisateurs',
      value: 0,
    },
  },
}

export const UsersDashBoardCard: React.FC<{ userRoleKind?: UserRoleKind }> = ({ userRoleKind }) => {
  const usersQuery = useUsersQuery({ 'roles.kind': userRoleKind })
  const type = userRoleKind ?? 'users'
  const totalUsers = usersQuery.data?.length ?? 0

  return (
    <Element className="has-text-employer">
      <DashboardCard
        title={labels[type].title}
        mainStat={{ value: totalUsers, label: labels[type].mainStat.label }}
        color={userRoleKind ? userRoleKindThemeNames[userRoleKind] : undefined}
        to={labels[type].to}
        queryStatus={usersQuery.status}
      />
    </Element>
  )
}
