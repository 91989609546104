// src/pages/interim-agency-settings/partner-employer-details.tsx

import React from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { Box } from 'react-bulma-components'
import { useOrganizationQuery } from '../../queries/organizations'
import { Employer } from '../../api/organizations'
import { OutletContext } from './interim-agency-settings-wrapper'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import LaborCostsCoefficientsTable from '../../components/organizations/labor-costs-coefficients-table'
import { useJobTitlesQuery } from '../../queries/job-titles'
import { sortByIgnoreCase } from '../../utils/arrays'
import { JobTitle } from '../../api/job-titles'

/**
 * Component for displaying details of a partner employer for an interim agency
 */
const PartnerEmployerDetails: React.FC = () => {
  const { id } = useParams()
  const { interimAgency } = useOutletContext<OutletContext>()

  // Fetch employer details
  const { data: employer } = useOrganizationQuery<Employer>(id, 'employer')

  const jobTitlesQuery = useJobTitlesQuery({ employer: id })
  const jobTitles = sortByIgnoreCase<JobTitle>(jobTitlesQuery.data || [], 'name')

  if (!employer) {
    return null
  }

  return (
    <>
      <SidebarContentTitle title={`Partenariat avec ${employer.name}`} />
      <Box>
        <SidebarContentTitle title="Intitulés de Poste & Coefficients main d'oeuvre" />
        <LaborCostsCoefficientsTable jobTitles={jobTitles} interimAgency={interimAgency} />
      </Box>
    </>
  )
}

export default PartnerEmployerDetails
