import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './interim-agency-settings-wrapper'
import { useAppSubscriptionPeriodsByAgencyQuery } from '../../queries/app-subscription-periods'
import AppSubscriptionPeriodsAgencyList from '../../components/app-subscription-periods/periods-list'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import { Box } from 'react-bulma-components'
import { isAuthorizedFor } from '../../components/protected/protected'

/**
 * Component for managing agency subscription settings
 * Receives interimAgency from outlet context and passes it to the form
 */
type AppSubscriptionPeriodsProps = OutletContext

const AppSubscriptionPeriods: React.FC = () => {
  const { interimAgency } = useOutletContext<AppSubscriptionPeriodsProps>()
  const { data: allPeriods } = useAppSubscriptionPeriodsByAgencyQuery(interimAgency._id, {
    enabled: Boolean(interimAgency),
  })

  return (
    <>
      <SidebarContentTitle title="Périodes d'abonnement" />
      <Box>
        <AppSubscriptionPeriodsAgencyList
          data={allPeriods || []}
          itemPath={
            isAuthorizedFor(['superAdmin'])
              ? `/agencies/${interimAgency._id}/app-subscription-periods/`
              : '/settings/app-subscription-periods/'
          }
        />
      </Box>
    </>
  )
}

export default AppSubscriptionPeriods
